import React from 'react';
import { Button, Collapse, Divider } from 'antd';
import { GwConfigStepType } from 'gw-api/dist/types';
import OtpInput from '../common/OtpInput';
import PhoneNumberInput from '../common/PhoneNumberInput';
import Summary from '../components/GwFlowValidate/Summary';
import currentResultForValidate from '../mock/currentResultForValidate';
import { displayErrorWithRetry } from '../common/ErrorModal';
import { useTranslation } from 'react-i18next';
import GwFlowValidateError from '../components/GwFlowValidate/GwFlowVadiateError';

export default DebugCommon;
export type DebugCommonProps = {};

function DebugCommon({ ...rest }: DebugCommonProps) {
    const { t } = useTranslation();
    return (
        <Collapse
            destroyInactivePanel={true}
            defaultActiveKey={'GwFlowValidateError'}
        >
            <Collapse.Panel
                header="ErrorWithRetryModal"
                key="ErrorWithRetryModal"
            >
                <div style={{ width: '400px' }}>
                    <Button
                        onClick={() =>
                            displayErrorWithRetry(
                                new Error('Error message'),
                                {
                                    icon: null,

                                    title: t('No pudimos procesar tu selfie'),
                                    content: (
                                        <p>
                                            <br />
                                            {t(
                                                'Por favor, sigue estas indicaciones para tomar una nueva'
                                            )}
                                            :
                                            <ul>
                                                <li>
                                                    {t(
                                                        'Asegúrate de que tu rostro esté completamente visible y bien iluminado.'
                                                    )}
                                                </li>
                                                <li>
                                                    {t(
                                                        'No uses objetos que cubran tu rostro, como gafas o sombreros.'
                                                    )}
                                                </li>
                                                <li>
                                                    {t(
                                                        'Asegúrate de que la cámara esté al nivel de tus ojos.'
                                                    )}
                                                </li>
                                                <li>
                                                    {t(
                                                        'Evita los fondos muy iluminados o muy oscuros.'
                                                    )}
                                                </li>
                                                <li>
                                                    {t(
                                                        'Mantén una expresión neutral y mira directamente a la cámara.'
                                                    )}
                                                </li>
                                            </ul>
                                        </p>
                                    ),
                                    onRetry: () => alert('Retry'),
                                },
                                t
                            )
                        }
                    >
                        Open
                    </Button>
                </div>
            </Collapse.Panel>
            <Collapse.Panel header="Summary" key="Summary">
                <div style={{ width: '400px' }}>
                    <Summary
                        result={{
                            ...currentResultForValidate,
                            // [GwConfigStepType.DOCUMENT_CAPTURE]: {},
                            // [GwConfigStepType.SELFIE_CAPTURE]: {},
                            // [GwConfigStepType.PERSONAL_INFO]: {},
                            [GwConfigStepType.OTP_SMS]: {
                                phone: '+1234567890',
                            },
                        }}
                    />
                </div>
            </Collapse.Panel>
            <Collapse.Panel header="OtpInput" key="OtpInput">
                <OtpInput />
            </Collapse.Panel>
            <Collapse.Panel header="PhoneNumberInput" key="PhoneNumberInput">
                <div>
                    <Divider>Horizontal</Divider>
                    <PhoneNumberInput showFullCountryName={true} />
                    <Divider>Vertical</Divider>
                    <PhoneNumberInput
                        showFullCountryName={true}
                        direction="vertical"
                    />
                </div>
            </Collapse.Panel>
            <Collapse.Panel
                header="GwFlowValidateError"
                key="GwFlowValidateError"
            >
                <div>
                    <GwFlowValidateError
                        supportLink={'https://css.goboarding.tech'}
                        transactionId={
                            '123456-adfasdfasdfa-dfasdfasdfa-dsfasdfasdfadsfa-sdfasdfasd'
                        }
                    />
                </div>
            </Collapse.Panel>
        </Collapse>
    );
}
