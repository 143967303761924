import React, { useContext, useMemo, useState } from 'react';
import { GwFlowStepProps } from '../GwFlow';
import {
    CountryInfo,
    DocumentCaptureProvider,
    DocumentInfo,
    GwFlowDocumentCaptureOptions,
    GwFlowDocumentCaptureProviderError,
    GwFlowDocumentCaptureProviderResult,
    GwFlowDocumentCaptureValdationRule,
    MicroblinkExtractionResult,
    UserInfo,
} from 'gw-api/dist/types';
import GwFlowDocumentCaptureMicroblink from './GwFlowDocumentCaptureMicroblink';
import { Tabs } from 'antd';
import GwFlowDocumentCaptureTips from './GwFlowDocumentCaptureTips';

import { useTranslation } from 'react-i18next';
import GwFlowDocumentCaptureConfirm from './GwFlowDocumentCaptureConfirm';

import TitleText from '../../common/TitleText';
import StepContent from '../../common/StepContent';
import LoadingWrapper from '../../common/LoadingWrapper';
import dayjs from 'dayjs';
import useRetries from '../../common/useRetries';
import { DebugContext } from '../../common/Debug';
import { displayErrorWithRetry } from '../../common/ErrorModal';

export default GwFlowDocumentCapture;

export interface GwFlowDocumentCaptureProvider {
    onlyFront?: boolean;
    onError?: (error: GwFlowDocumentCaptureProviderError) => void;
    onSuccess?: (result: GwFlowDocumentCaptureProviderResult) => void;
    onCancel?: (result: GwFlowDocumentCaptureProviderResult) => void;
}

export interface GwFlowDocumentCaptureProps
    extends Omit<GwFlowStepProps, 'options'> {
    options?: GwFlowDocumentCaptureOptions;
}

function GwFlowDocumentCapture({
    options = {},
    onResult,
    onCancel,
    onError,
}: Partial<GwFlowDocumentCaptureProps>) {
    const { t, i18n } = useTranslation();
    const { logDebug, logWarning, logError } = useContext(DebugContext);
    const {
        provider = DocumentCaptureProvider.MICROBLINK,
        showTips,
        showPreview,
        enableEditOcr,
        validations,
        retries,
    } = options;
    const { consumeRetry, getRetryError, retriesLeft } = useRetries(retries);

    const [tmpResult, setTmpResult] =
        useState<GwFlowDocumentCaptureProviderResult>();
    const handleTipClick = () => {
        setActiveKey('scan');
    };

    const validateExtraction = (
        extractionResult: MicroblinkExtractionResult
    ) => {
        const minAgeRule = validations?.find((rule) => rule.minAge);
        if (minAgeRule?.minAge) {
            logDebug('Validating age...');
            validateMinAge(minAgeRule, extractionResult, t);
            logDebug('Age is valid.');
        }
        // validateDocumentType(document!, extractionResult, t);
        // validateCountry(country!, extractionResult, t);
        // validateFaceImage(extractionResult, t);
        validateMatchFrontBack(extractionResult, t);
    };
    const handleCaptureSuccess = async (
        result: GwFlowDocumentCaptureProviderResult
    ) => {
        const purgedResult = {
            ...result,
            rawScanResult: { classInfo: result.rawScanResult?.classInfo },
        };
        try {
            await validateExtraction(result.rawScanResult!);
            if (showPreview) {
                setTmpResult(purgedResult);
                setActiveKey('preview');
            } else {
                onResult?.(purgedResult);
            }
        } catch (err) {
            logWarning(err.message);
            logDebug(`Retries left: ${retriesLeft}`);
            if (consumeRetry()) {
                displayErrorWithRetry(
                    err,
                    {
                        icon: null,
                        title: getCaptureErrorTitle(err, t),
                        content: getCaptureErrorSubtitle(err, t),
                        onCancel: () => {
                            logWarning(`User canceled document capture`);
                            onResult?.({
                                ...purgedResult,
                                status: 'warning',
                                statusMessage: 'User canceled document capture',
                            });
                        },
                        onRetry: () => {
                            logDebug(`Retrying document capture`);
                            setActiveKey('idle');
                            setTimeout(() => {
                                setActiveKey('scan');
                            });
                        },
                    },
                    t
                );
            } else {
                logDebug(`No more retries left`);
                onResult?.({
                    ...purgedResult,
                    status: 'warning',
                    statusMessage: err.message,
                });
            }
        }
    };
    const handleCaptureError = (error: any) => {
        if (showTips) {
            setActiveKey('tips');
        }
        onError?.(error);
    };
    const handleCaptureCancel = () => {
        if (showPreview) {
            setActiveKey('tips');
        } else {
            onCancel?.();
        }
    };

    const handleCaptureConfirm = (newUserInfo?: UserInfo) => {
        let result = { ...tmpResult };
        if (enableEditOcr && newUserInfo) {
            result = { ...result, userInfo: newUserInfo };
        }
        onResult?.(result);
    };
    const handleCaptureRetake = () => {
        setActiveKey('scan');
    };
    const items = [
        ...(showTips
            ? [
                  {
                      label: '',
                      key: 'tips',
                      children: (
                          <StepContent
                              header={<TitleText size="large"></TitleText>}
                              body={
                                  <GwFlowDocumentCaptureTips
                                      onClick={handleTipClick}
                                  />
                              }
                          ></StepContent>
                      ),
                  },
              ]
            : []),
        {
            label: '',
            key: 'scan',
            children:
                provider === DocumentCaptureProvider.MICROBLINK ? (
                    <GwFlowDocumentCaptureMicroblink
                        locale={i18n}
                        onSuccess={handleCaptureSuccess}
                        onError={handleCaptureError}
                        onCancel={handleCaptureCancel}
                    />
                ) : (
                    'Unknown provider'
                ),
        },
        ...(showPreview
            ? [
                  {
                      label: '',
                      key: 'preview',
                      children: (
                          <StepContent
                              header={<TitleText size="large"></TitleText>}
                              body={
                                  <GwFlowDocumentCaptureConfirm
                                      result={tmpResult!}
                                      onConfirm={handleCaptureConfirm}
                                      onCancel={handleCaptureRetake}
                                      confirmText={t('Aceptar')}
                                      cancelText={t('Tomar documento de nuevo')}
                                  />
                              }
                          ></StepContent>
                      ),
                  },
              ]
            : []),

        {
            label: '',
            key: 'idle',
            children: <LoadingWrapper />,
        },
    ];

    const [activeKey, setActiveKey] = useState<string>(items[0].key);
    return (
        <div className="GwFlowDocumentCapture" style={{ margin: 0 }}>
            <Tabs
                items={items}
                renderTabBar={() => <span></span>}
                activeKey={activeKey}
                destroyInactiveTabPane={true}
            />
        </div>
    );
}

// function documentTypeToBlinkDocumentType(document: DocumentInfo) {
//     switch (document.code) {
//         case DocumentCode.ID_CARD:
//             return DocumentType.ID;
//         case DocumentCode.PASSPORT:
//             return DocumentType.PASSPORT;
//         case DocumentCode.DRIVING_LICENSE:
//             return DocumentType.DL;
//         case DocumentCode.VISA:
//             return DocumentType.VISA;
//         default:
//             return DocumentType.NONE;
//     }
// }

//@TODO refactor this function to reuse validation conde from Personal Info step
export function validateMinAge(
    minAgeRule: GwFlowDocumentCaptureValdationRule,
    result: any,
    t?: any
) {
    const formattedDate = `${result.dateOfBirth.year}-${String(
        result.dateOfBirth.month
    ).padStart(2, '0')}-${String(result.dateOfBirth.day).padStart(2, '0')}`;

    const age = dayjs().diff(dayjs(formattedDate), 'year');
    if (age < minAgeRule.minAge) {
        throw new Error(
            t(minAgeRule.message) ||
                `${t('La edad debe ser mayor a')} ${minAgeRule.minAge} ${t(
                    'años'
                )}`
        );
    }
}
function validateDocumentType(document: DocumentInfo, result: any, t: any) {
    if (!result.classInfo.documentType) {
        throw new Error(t('El tipo de documento no pudo ser capturado'));
    }
    if (
        // documentTypeToBlinkDocumentType(document) !==
        result.classInfo.documentType
    ) {
        throw new Error(t('El tipo del documento no es correcto'));
    }
}

function validateCountry(country: CountryInfo, result: any, t: any) {
    if (!result.classInfo.isoAlpha3CountryCode) {
        throw new Error(t('El pais del documento no pudo ser capturado'));
    }
    if (country.alpha3 !== result.classInfo.isoAlpha3CountryCode) {
        throw new Error(t('El país del documento no es correcto'));
    }
}

function validateFaceImage(result: any, t: any) {
    if (result.faceImage.rawImage === null) {
        throw new Error(t('El documento es invalido'));
    }
}

function validateMatchFrontBack(result: any, t: any) {
    if (result.dataMatchResult?.state === 1) {
        // DataMatchResult failed
        throw new Error(`${t('Front and back does not match')}`);
    }
}

export function getCaptureErrorTitle(error: any, t: any): string {
    if (error?.message.includes('[DocumentCapture]')) {
        return t('No pudimos procesar tu documento');
    } else {
        return t('Ha ocurrido un error');
    }
}
export function getCaptureErrorSubtitle(error: any, t: any): React.ReactNode {
    if (error?.message.includes('[DocumentCapture]')) {
        return (
            <p>
                <br />
                {t('Por favor, sigue estas indicaciones para tomar una nueva')}:
                <ul>
                    <li>
                        {t(
                            'Asegúrate de que el documento esté completamente visible y bien iluminado.'
                        )}
                    </li>
                    <li>
                        {t('Evita los fondos muy iluminados o muy oscuros.')}
                    </li>
                    <li>{t('Utiliza un documento veridico.')}</li>
                    <li>
                        {t(
                            'Comprueba que el frente corresponda al dorso del documento.'
                        )}
                    </li>
                </ul>
            </p>
        );
    } else {
        return t('Ha ocurrido un error');
    }
}
