import React from 'react';
import { Collapse, Modal } from 'antd';
import GwFlowFaceCapture, {
    GwFlowFaceCaptureProps,
} from '../components/GwFlowFaceCapture/GwFlowFaceCapture';
import { FaceCaptureProvider } from 'gw-api/dist/types';
import { debugDataWithModal } from './DebugComponentsPage';
import { GwFlowStepResult } from '../components/GwFlow';
import { ConfigProvider } from '../components/ConfigContext';

export default DebugGwFlowFaceCapture;
export type DebugGwFlowFaceCaptureProps = {};

function DebugGwFlowFaceCapture({ ...rest }: DebugGwFlowFaceCaptureProps) {
    const onResult = (result: GwFlowStepResult) => {
        const { rawScanResult, ...rest } = result;
        console.log(result);
        debugDataWithModal('', {
            ...rest,
        });
    };
    const samples: {
        title: string;
        props: GwFlowFaceCaptureProps;
    }[] = [
        {
            title: 'idrnd with check liveness',
            props: {
                options: {
                    provider: FaceCaptureProvider.IDRND,
                    checkLiveness: true,
                    showPreview: true,
                },
                onResult: onResult,
                onCancel: () => Modal.info({ title: 'canceled' }),
                onError: (error) => Modal.info({ title: error.message }),
                currentResult: {},
            },
        },

        {
            title: 'Default',
            props: {
                options: {},
                onResult: onResult,
                onCancel: () => Modal.info({ title: 'canceled' }),
                onError: (error) => Modal.info({ title: error.message }),
                currentResult: {},
            },
        },
        {
            title: 'With show tips and preview',
            props: {
                options: {
                    showTips: true,
                    showPreview: true,
                },
                onResult: onResult,
                onCancel: () => Modal.info({ title: 'canceled' }),
                onError: (error) => Modal.info({ title: error.message }),
                currentResult: {},
            },
        },
        {
            title: 'With show tips and preview with retries',
            props: {
                options: {
                    showTips: true,
                    showPreview: true,
                    retries: 1,
                },
                onResult: onResult,
                onCancel: () => Modal.info({ title: 'canceled' }),
                onError: (error) => Modal.info({ title: error.message }),
                currentResult: {},
            },
        },
    ];
    return (
        <Collapse destroyInactivePanel={true}>
            {samples.map(({ title, props }, index) => {
                return (
                    <Collapse.Panel header={title} key={`fragment-${index}`}>
                        <ConfigProvider
                            defaultTransactionId={
                                '9907e586-539d-4f4b-bbc6-579a24517000'
                            }
                            defaultClientId={25}
                            config={{}}
                        >
                            <GwFlowFaceCapture {...props} />
                        </ConfigProvider>
                    </Collapse.Panel>
                );
            })}
        </Collapse>
    );
}
