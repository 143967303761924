import React, { useState } from 'react';
import { GwFlowStepProps, GwFlowStepResult } from '../GwFlow';
import TitleText from '../../common/TitleText';
import { Form, Modal } from 'antd';
import StepContent from '../../common/StepContent';
import { useTranslation } from 'react-i18next';
import type { RcFile } from 'antd/es/upload/interface';
import { GwFlowUploadsItem, GwFlowUploadsOptions } from 'gw-api/dist/types';

import { PlusCircleOutlined } from '@ant-design/icons';
import Button from '../../common/Button';
import Dragger from 'antd/es/upload/Dragger';
import './GwFlowUploads.css';
export default GwFlowUploads;

export enum GwFlowUploadsFieldType {
    file = 'file',
}

export type GwFlowUploadsFieldFormValues = {};

export interface GwFlowUploadsProps extends Omit<GwFlowStepProps, 'options'> {
    options?: GwFlowUploadsOptions;
}

function GwFlowUploads({
    onResult,
    onCancel,
    options = {},
}: Partial<GwFlowUploadsProps>) {
    const { t } = useTranslation();
    const [form] = Form.useForm<GwFlowUploadsFieldFormValues>();
    const [formValues, setFormValues] = useState<Record<string, string>>({});
    const {
        accept = '.jpg,.png,.pdf',
        maxMb = 2,
        items = [
            {
                key: 'document',
                label: 'Comprobante de domicilio',
            },
        ],
        required,
    } = options;

    const handleFinish = async () => {
        // const values = await form.validateFields();
        onResult?.({ ...formValues });
    };

    const handleFileChange = async (info: any, item: GwFlowUploadsItem) => {
        const originalFile =
            info.file?.originFileObj || info.fileList?.[0]?.originFileObj;
        if (originalFile) {
            getBase64(originalFile, (base64: string) => {
                // Update the state with the Base64 string
                setFormValues((prevValues) => ({
                    ...prevValues,
                    [item.key]: base64,
                }));
            });
        } else {
            setFormValues((prevValues) =>
                Object.assign(prevValues, { [item.key]: undefined })
            );
        }
    };

    const getBase64 = (file: File, callback: (base64: string) => void) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => callback(reader.result as string);
        reader.onerror = (error) => console.log('Error: ', error);
    };

    return (
        <StepContent
            header={
                <TitleText size="large">
                    {t('Necesitaremos más información')}
                </TitleText>
            }
            body={
                <Form
                    form={form}
                    layout="vertical"
                    onFinish={handleFinish}
                    className="GwFlowUploads"
                >
                    {items?.map((item: GwFlowUploadsItem) => {
                        const max = item.maxMb || maxMb;
                        const acc = item.accept || accept;
                        return (
                            <Form.Item
                                key={item.key}
                                name={item.key}
                                rules={[
                                    {
                                        required:
                                            item.required !== undefined
                                                ? item.required
                                                : required !== undefined
                                                  ? required
                                                  : true,
                                        message: `${item.label} es requerido`,
                                    },
                                ]}
                            >
                                <Dragger
                                    beforeUpload={async (file: RcFile) => {
                                        await new Promise((resolve, reject) => {
                                            const max = item.maxMb || maxMb;
                                            const isLtMax =
                                                file.size / 1024 / 1024 < max;

                                            if (!isLtMax) {
                                                Modal.error({
                                                    content: `${t(
                                                        'El tamaño del archivo debe ser menor a'
                                                    )} ${max}MB`,
                                                });
                                                return false;
                                            }
                                            resolve(true);
                                        });
                                        return false;
                                    }} // Prevent automatic upload
                                    accept={acc}
                                    maxCount={1}
                                    listType="picture"
                                    onChange={(info) =>
                                        handleFileChange(info, item)
                                    }
                                >
                                    <p className="ant-upload-drag-icon">
                                        <PlusCircleOutlined />
                                    </p>
                                    <p className="ant-upload-text">
                                        {t(item.label)}
                                        {acc && max ? (
                                            <small>
                                                <br />
                                                {t(
                                                    'Extensiones aceptadas'
                                                )}: {acc}, {t('Tamaño maximo')}:{' '}
                                                {max}MB
                                            </small>
                                        ) : (
                                            ''
                                        )}
                                        {acc && !max ? (
                                            <small>
                                                <br />
                                                {t('Extensiones aceptadas')}:
                                                {acc}
                                            </small>
                                        ) : (
                                            ''
                                        )}
                                        {!acc && max ? (
                                            <small>
                                                <br />
                                                {t('Tamaño maximo')}: {max}MB
                                            </small>
                                        ) : (
                                            ''
                                        )}
                                    </p>
                                    <p className="ant-upload-hint">
                                        {t(
                                            'Haz click o arrastra el archivo al area para subir'
                                        )}
                                    </p>
                                </Dragger>
                            </Form.Item>
                        );
                    })}
                    <br />
                    <Button htmlType="submit" type="primary">
                        {t('Continuar')}
                    </Button>
                </Form>
            }
        />
    );
}
