import React, { useEffect, useMemo, useState } from 'react';
import './App.css';
import getI18n from './i18n';
import localeEs from 'antd/es/date-picker/locale/es_ES';
import localeEn from 'antd/es/date-picker/locale/en_US';
import localePt from 'antd/es/date-picker/locale/pt_BR';
import { Layout, Result, ConfigProvider as AntConfigProvider } from 'antd';
import { deviceDetect } from 'react-device-detect';

import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

import { createStylesheet, updateFavicon } from './helpers';
import GwService from './services/GwService';
import { StyleThemeProvider } from './common/StyleContext';
import DebugComponentsPage from './debug/DebugComponentsPage';
import { ConfigProvider } from './components/ConfigContext';
import GwFlowApp from './GwFlowApp';
import { ClientParamsGwConfig, ClientParams } from 'gw-api/dist/types';

// @ts-ignore
import { ApiError } from './services/error';
import LoginPage from './components/LoginPage';
import { useTranslation } from 'react-i18next';

// debug vars form clientDataConfig
// window.SORTVARS.config?.clientDataConfig &&
//     (window.SORTVARS.config.clientDataConfig.mobileOnly = true);

const locales: { [key: string]: any } = {
    ['en']: localeEn,
    ['en-US']: localeEn,
    ['es']: localeEs,
    ['es-UY']: localeEs,
    ['pt']: localePt,
};

GwService.setAddr(`${process.env.REACT_APP_SMARTFACE_BASE_API}`);
function App({ clientParams }: { clientParams: ClientParams }) {
    const [error] = useState(clientParams.error);
    const [clientConfiguration] = useState<ClientParamsGwConfig>(
        clientParams.config || {}
    );
    const locale = useMemo(
        () => locales[getI18n().language.toLocaleLowerCase()],
        []
    );
    const { t } = useTranslation();

    useEffect(() => {
        if (clientParams.transactionId) {
            GwService.setTransactionId(clientParams.transactionId);
        } else {
            console.error('transactionId not present');
        }
        if (clientConfiguration) {
            const defaultUiConfig = {
                backgroundImage: 'rectangle-10.svg',
                logoUrl: 'logo_FacePhi.svg',
                primaryColor: '#00A5E9',
                // linkColor: '#2757a3',
                // successColor: '#52c41a',
                // warningColor: '#faad14',
                // errorColor: '#f5222d',
                // fontSizeBase: '14px',
                // headingColor: '#000000',
                // textColor: '#000000',
                // textColorSecondary: '#000000',
                borderRadiusBase: '8px',
                // borderColorBase: '#d9d9d9',
                // boxShadowBase:
                //     '0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05)',
            };
            createStylesheet({
                ...defaultUiConfig,
                ...(clientConfiguration.uiConfig
                    ? clientConfiguration.uiConfig
                    : {}),
            });
            let faviconUrl = 'favicon.ico'; //default
            if (clientConfiguration.uiConfig?.favicon) {
                faviconUrl = clientConfiguration.uiConfig.favicon;
            }
            updateFavicon(faviconUrl);
        }
    }, []);

    if (error) {
        return (
            <Result
                status={'error'}
                title={getErrorTitle(error, t)}
                subTitle={getErrorSubtitle(error, t)}
            />
        );
    }

    return (
        <Layout className="App">
            <StyleThemeProvider initialStyleTheme={clientConfiguration}>
                <AntConfigProvider locale={locale}>
                    <Layout.Content className="App-content">
                        <div className="site-layout-content">
                            <Router>
                                <Switch>
                                    <Route
                                        exact
                                        path="/debugComponents/:componentName?"
                                        component={DebugComponentsPage}
                                    />
                                    <Route
                                        path="/"
                                        exact={true}
                                        render={() => (
                                            <ConfigProvider
                                                defaultTransactionId={
                                                    window.SORTVARS
                                                        ?.transactionId
                                                }
                                                defaultClientId={
                                                    window.SORTVARS?.clientId
                                                }
                                                config={window.SORTVARS?.config}
                                                defaultToken={
                                                    window.SORTVARS?.token
                                                }
                                                defaultLocale={locale}
                                                geoLocation={
                                                    window.SORTVARS?.geoLocation
                                                }
                                            >
                                                <GwFlowApp
                                                    defaultValues={
                                                        window.SORTVARS
                                                            ?.defaultValues
                                                    }
                                                    activeStepIndex={
                                                        window.SORTVARS
                                                            ?.nextStepIndex || 0
                                                    }
                                                    flowInOtherDevice={
                                                        !!window.SORTVARS
                                                            ?.flowInOtherDevice
                                                    }
                                                    processFinished={
                                                        !!window.SORTVARS
                                                            ?.processFinished
                                                    }
                                                    defaultSessionExpired={
                                                        !!window.SORTVARS
                                                            ?.sessionExpired
                                                    }
                                                    initialResult={
                                                        window.SORTVARS?.result
                                                    }
                                                />
                                            </ConfigProvider>
                                        )}
                                    />
                                </Switch>
                            </Router>
                        </div>
                    </Layout.Content>
                </AntConfigProvider>
            </StyleThemeProvider>
        </Layout>
    );
}

export default App;

export function getErrorTitle(error: any, t: any): string {
    switch (error?.message) {
        case 'Invalid OTP':
            return t('Sesión finalizada');
        default:
            return t('Ha ocurrido un error');
    }
}
export function getErrorSubtitle(error: any, t: any): string {
    switch (error?.message) {
        case 'Invalid OTP':
            return t(
                'El tiempo de validez de la sesión ha expirado, tienes que iniciar un nuevo proceso.'
            );
        default:
            return t(
                'Porfavor, intente nuevamente más tarde. Si el problema persiste, contacte a soporte técnico.'
            );
    }
}
