import React, { Fragment } from 'react';
import { Row, Col, Form, FormInstance } from 'antd';
import { GwFlowPersonalInfoFieldMap } from './GwFlowPersonalInfo';

import { GwFlowPersonalInfoFieldType } from 'gw-api/dist/types';
import { useTranslation } from 'react-i18next';
import Input from '../../common/Input';
import Select from '../../common/Select';

export default GwFlowPersonalInfoAdvancedForm;

export type GwFlowPersonalInfoAdvancedFormProps = {
    form: FormInstance;
    fieldsMap: GwFlowPersonalInfoFieldMap;
};

function GwFlowPersonalInfoAdvancedForm({
    form,
    fieldsMap,
}: GwFlowPersonalInfoAdvancedFormProps) {
    const { t } = useTranslation();
    return (
        <Row gutter={20} className="GwFlowPersonalInfoAdvancedForm">
            {Object.values(fieldsMap)
                .filter((field) => field.show)
                .map((field) => {
                    const { type, show, disabled, ...restField } = field;
                    if (type === GwFlowPersonalInfoFieldType.occupation) {
                        return (
                            <Col xs={24} sm={24} md={24} key="occupation">
                                <Form.Item name="occupation" {...restField}>
                                    <Input
                                        placeholder={t(
                                            'Ingresa tu ocupación/profesión'
                                        )}
                                        disabled={disabled}
                                    />
                                </Form.Item>
                            </Col>
                        );
                    } else if (type === GwFlowPersonalInfoFieldType.education) {
                        return (
                            <Col xs={24} sm={24} md={24} key="education">
                                <Form.Item name="education" {...restField}>
                                    <Select
                                        placeholder={t(
                                            'Elige tu nivel de educación'
                                        )}
                                        disabled={disabled}
                                        style={{
                                            textAlign: 'left',
                                        }}
                                    >
                                        <Select.Option value="no-education">
                                            {t('Sin educación')}
                                        </Select.Option>
                                        <Select.Option value="primary-school">
                                            {t('Educación primaria')}
                                        </Select.Option>
                                        <Select.Option value="secondary-school">
                                            {t('Educación secundaria')}
                                        </Select.Option>
                                        <Select.Option value="tertiary-education">
                                            {t('Educación terciaria')}
                                        </Select.Option>
                                        <Select.Option value="university-degree">
                                            {t('Universitario')}
                                        </Select.Option>
                                    </Select>
                                </Form.Item>
                            </Col>
                        );
                    } else if (
                        type === GwFlowPersonalInfoFieldType.maritalStatus
                    ) {
                        return (
                            <Col
                                xs={24}
                                sm={24}
                                md={24}
                                key="maritalStatusWrapper"
                            >
                                <Row gutter={20}>
                                    <Col xs={24} sm={24} md={24}>
                                        <Form.Item
                                            name="maritalStatus"
                                            key="maritalStatus"
                                            {...restField}
                                        >
                                            <Select
                                                placeholder={t(
                                                    'Elige tu estado civil'
                                                )}
                                                disabled={disabled}
                                                style={{
                                                    textAlign: 'left',
                                                }}
                                            >
                                                <Select.Option value="SINGLE">
                                                    {t('Soltero/a')}
                                                </Select.Option>
                                                <Select.Option value="MARRIED">
                                                    {t('Casado/a')}
                                                </Select.Option>
                                                <Select.Option value="WIDOWED">
                                                    {t('Viudo/a')}
                                                </Select.Option>
                                                <Select.Option value="DIVORCED">
                                                    {t('Divorciado/a')}
                                                </Select.Option>
                                            </Select>
                                        </Form.Item>
                                    </Col>

                                    <Col xs={24} sm={24} md={24}>
                                        <Form.Item
                                            noStyle={true}
                                            shouldUpdate={(
                                                prevValues,
                                                nextValues
                                            ) =>
                                                prevValues.maritalStatus !==
                                                nextValues.maritalStatus
                                            }
                                        >
                                            {() => {
                                                return (
                                                    form.getFieldValue(
                                                        'maritalStatus'
                                                    ) === 'MARRIED' && (
                                                        <Fragment>
                                                            {fieldsMap[
                                                                GwFlowPersonalInfoFieldType
                                                                    .spouseName
                                                            ] === undefined ||
                                                            fieldsMap[
                                                                GwFlowPersonalInfoFieldType
                                                                    .spouseName
                                                            ]?.show ? (
                                                                <Form.Item
                                                                    label={
                                                                        fieldsMap[
                                                                            GwFlowPersonalInfoFieldType
                                                                                .spouseName
                                                                        ]?.label
                                                                    }
                                                                    name="spouseName"
                                                                    key="spouseName"
                                                                    rules={
                                                                        fieldsMap[
                                                                            GwFlowPersonalInfoFieldType
                                                                                .spouseName
                                                                        ]?.rules
                                                                    }
                                                                >
                                                                    <Input
                                                                        placeholder={t(
                                                                            'Ingresa nombre del conyuge'
                                                                        )}
                                                                        disabled={
                                                                            fieldsMap[
                                                                                GwFlowPersonalInfoFieldType
                                                                                    .spouseName
                                                                            ]
                                                                                ?.disabled
                                                                        }
                                                                    />
                                                                </Form.Item>
                                                            ) : null}

                                                            {fieldsMap[
                                                                GwFlowPersonalInfoFieldType
                                                                    .spouseSurname
                                                            ] === undefined ||
                                                            fieldsMap[
                                                                GwFlowPersonalInfoFieldType
                                                                    .spouseSurname
                                                            ]?.show ? (
                                                                <Form.Item
                                                                    label={
                                                                        fieldsMap[
                                                                            GwFlowPersonalInfoFieldType
                                                                                .spouseSurname
                                                                        ]?.label
                                                                    }
                                                                    name="spouseSurname"
                                                                    key="spouseSurname"
                                                                    rules={
                                                                        fieldsMap[
                                                                            GwFlowPersonalInfoFieldType
                                                                                .spouseSurname
                                                                        ]?.rules
                                                                    }
                                                                    {...restField}
                                                                >
                                                                    <Input
                                                                        placeholder={t(
                                                                            'Ingresa apellido del conyuge'
                                                                        )}
                                                                        disabled={
                                                                            fieldsMap[
                                                                                GwFlowPersonalInfoFieldType
                                                                                    .spouseSurname
                                                                            ]
                                                                                ?.disabled
                                                                        }
                                                                    />
                                                                </Form.Item>
                                                            ) : null}
                                                            {fieldsMap[
                                                                GwFlowPersonalInfoFieldType
                                                                    .spouseIDNumber
                                                            ] === undefined ||
                                                            fieldsMap[
                                                                GwFlowPersonalInfoFieldType
                                                                    .spouseIDNumber
                                                            ]?.show ? (
                                                                <Form.Item
                                                                    label={
                                                                        fieldsMap[
                                                                            GwFlowPersonalInfoFieldType
                                                                                .spouseIDNumber
                                                                        ]?.label
                                                                    }
                                                                    name="spouseIDNumber"
                                                                    key="spouseIDNumber"
                                                                    rules={
                                                                        fieldsMap[
                                                                            GwFlowPersonalInfoFieldType
                                                                                .spouseIDNumber
                                                                        ]?.rules
                                                                    }
                                                                >
                                                                    <Input
                                                                        placeholder={t(
                                                                            'Ingresa ID del conyuge'
                                                                        )}
                                                                        disabled={
                                                                            fieldsMap[
                                                                                GwFlowPersonalInfoFieldType
                                                                                    .spouseSurname
                                                                            ]
                                                                                ?.disabled
                                                                        }
                                                                    />
                                                                </Form.Item>
                                                            ) : null}
                                                        </Fragment>
                                                    )
                                                );
                                            }}
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Col>
                        );
                    }
                })}
        </Row>
    );
}
