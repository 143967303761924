import React, { Fragment } from 'react';
import { GwConfigStepType, GwFlowGlobalResult } from 'gw-api/dist/types';
import Space from '../../common/Space';
import { useTranslation } from 'react-i18next';
import './Summary.css';
import { CheckCircleOutlined } from '@ant-design/icons';
import Image from '../../common/Image';
import {
    fieldTypes,
    getFieldsMap,
} from '../GwFlowPersonalInfo/GwFlowPersonalInfo';

export default Summary;

export type SummaryProps = {
    result: GwFlowGlobalResult;
};

function Summary({ result }: SummaryProps) {
    const { t } = useTranslation();

    const {
        [GwConfigStepType.DOCUMENT_CAPTURE]: documentData,
        [GwConfigStepType.SELFIE_CAPTURE]: selfieData,
        [GwConfigStepType.PERSONAL_INFO]: personalInfo,
        [GwConfigStepType.OTP_EMAIL]: otpEmail,
        [GwConfigStepType.OTP_SMS]: otpSms,
        [GwConfigStepType.WELCOME]: welcomeData,
    } = result;

    const fieldsMap = getFieldsMap(t);

    return (
        <div className="Summary">
            <Space wide={true} direction="vertical">
                {otpEmail ? (
                    <Space
                        className="Summary_item"
                        wide={true}
                        direction="horizontal"
                    >
                        <Space>
                            <span className="Summary_item-icon">
                                <CheckCircleOutlined />
                            </span>
                            <strong>Email</strong>
                        </Space>
                        <span>{otpEmail.email}</span>
                    </Space>
                ) : null}
                {otpSms ? (
                    <Space
                        className="Summary_item"
                        wide={true}
                        direction="horizontal"
                    >
                        <Space>
                            <span className="Summary_item-icon">
                                <CheckCircleOutlined />
                            </span>
                            <strong>{t('Numero de teléfono')}</strong>
                        </Space>
                        <span>{otpSms.phone}</span>
                    </Space>
                ) : null}
                {documentData && selfieData ? (
                    <Space
                        className="Summary_item"
                        wide={true}
                        direction="vertical"
                    >
                        <Space wide={true} justify="start">
                            <span className="Summary_item-icon">
                                <CheckCircleOutlined />
                            </span>
                            <strong>{t('Prueba de vida superada')}</strong>
                        </Space>
                        <Space
                            wide={true}
                            justify="space-between"
                            align="center"
                        >
                            <Space direction="vertical" align="start">
                                {documentData?.images?.face ? (
                                    <Image
                                        src={`${documentData?.images?.face}`}
                                        style={{
                                            width: '100px',
                                            height: '100px',
                                            objectFit: 'cover',
                                        }}
                                        className={undefined}
                                        alt={undefined}
                                    />
                                ) : documentData?.images?.front ? (
                                    <Image
                                        src={`${documentData?.images?.front}`}
                                        style={{
                                            width: '100px',
                                            height: '100px',
                                            objectFit: 'cover',
                                        }}
                                        className={undefined}
                                        alt={undefined}
                                    />
                                ) : (
                                    <Image
                                        style={{
                                            width: '100px',
                                            height: '100px',
                                            objectFit: 'cover',
                                        }}
                                        className={undefined}
                                        src={undefined}
                                        alt={undefined}
                                    />
                                )}
                                <span>{t('Foto documento')}</span>
                            </Space>
                            <Space style={{ fontSize: '32px' }}>
                                <hr style={{ width: '40px' }} />
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="1em"
                                    height="1em"
                                    viewBox="0 0 32 32"
                                >
                                    <path
                                        fill="currentColor"
                                        d="M12 2C8.145 2 5 5.145 5 9c0 2.41 1.23 4.55 3.094 5.813C4.527 16.343 2 19.883 2 24h2c0-4.43 3.57-8 8-8c1.375 0 2.656.36 3.781.969A8.007 8.007 0 0 0 14 22c0 4.406 3.594 8 8 8c4.406 0 8-3.594 8-8c0-4.406-3.594-8-8-8a7.964 7.964 0 0 0-4.688 1.531a10.134 10.134 0 0 0-1.406-.719A7.024 7.024 0 0 0 19 9c0-3.855-3.145-7-7-7m0 2c2.773 0 5 2.227 5 5s-2.227 5-5 5s-5-2.227-5-5s2.227-5 5-5m10 12c3.324 0 6 2.676 6 6s-2.676 6-6 6s-6-2.676-6-6s2.676-6 6-6m3.281 3.281L22 22.563l-2.281-2.282l-1.438 1.438l3 3l.719.687l.719-.687l4-4z"
                                    />
                                </svg>
                                <hr style={{ width: '40px' }} />
                            </Space>
                            <Space direction="vertical" align="end">
                                {selfieData?.bestImage ? (
                                    <Image
                                        src={`${selfieData?.bestImage}`}
                                        style={{
                                            width: '100px',
                                            height: '100px',
                                            objectFit: 'cover',
                                        }}
                                        className={undefined}
                                        alt={undefined}
                                    />
                                ) : (
                                    <Image
                                        style={{
                                            width: '100px',
                                            height: '100px',
                                        }}
                                        className={undefined}
                                        src={undefined}
                                        alt={undefined}
                                    />
                                )}
                                <span>{t('Foto selfie')}</span>
                            </Space>
                        </Space>
                        {/*<span>{personalInfo}</span>*/}
                    </Space>
                ) : null}

                {personalInfo ? (
                    <Space
                        className="Summary_item"
                        wide={true}
                        direction="vertical"
                    >
                        <Space wide={true} justify="start">
                            <span className="Summary_item-icon">
                                <CheckCircleOutlined />
                            </span>
                            <strong>{t('Datos personales')}</strong>
                        </Space>
                        {fieldTypes.map((field) => {
                            if (personalInfo.personalInfo[field]) {
                                return (
                                    <Space wide={true} justify="space-between">
                                        <span>{fieldsMap[field].label}</span>
                                        <span>
                                            {personalInfo.personalInfo[field]}
                                        </span>
                                    </Space>
                                );
                            } else {
                                return null;
                            }
                        })}
                    </Space>
                ) : null}
            </Space>
        </div>
    );
}
