import React from 'react';
import { Collapse } from 'antd';
import GwFlow, { GwFlowProps } from '../components/GwFlow';
import { ConfigProvider } from '../components/ConfigContext';
import {} from '../components/GwFlowFaceCapture/GwFlowFaceCapture';
import { debugDataWithModal } from './DebugComponentsPage';
import GwService from '../services/GwService';

import {
    DocumentCode,
    FaceCaptureProvider,
    GwFlowFaceCaptureOptions,
    GwFlowDocumentTypeOptions,
    GwFlowPersonalInfoOptions,
    GwFlowOtpOptions,
    GwFlowResultOptions,
    GwFlowDocumentCaptureOptions,
    GwFlowGlobalResult,
} from 'gw-api/dist/types';

export default DebugGwFlowDocumentTypeSelect;
export type DebugGwFlowProps = {};

function DebugGwFlowDocumentTypeSelect({ ...rest }: DebugGwFlowProps) {
    const onFinish = (result: GwFlowGlobalResult) => {
        const { ...rest } = result;
        debugDataWithModal('', {
            ...rest,
        });
    };
    const onPartialResult = async (result: GwFlowGlobalResult) => {
        try {
            await GwService.update({ index: 1, result });
        } catch (e) {}
    };
    const samples: {
        title: string;
        props: GwFlowProps;
    }[] = [
        {
            title: 'Full microblink / idrnd',
            props: {
                onFinish: onFinish,
                onPartialResult,
                config: {
                    steps: [
                        { type: 'WELCOME' },

                        {
                            type: 'OTP_EMAIL',
                            options: {} as GwFlowOtpOptions,
                        },
                        {
                            type: 'DOCUMENT_TYPE_SELECT',
                            options: {
                                defaultDocumentCode: 'ID' as DocumentCode,
                                defaultCountryCode: 'URY',
                            } as GwFlowDocumentTypeOptions,
                        },
                        {
                            type: 'DOCUMENT_CAPTURE',
                            options: {
                                showTips: true,
                                showPreview: true,
                            } as GwFlowDocumentCaptureOptions,
                        },
                        {
                            type: 'PERSONAL_INFO',
                            options: {
                                fullFields: true,
                                useGeolocation: true,
                            } as GwFlowPersonalInfoOptions,
                        },

                        {
                            type: 'OTP_SMS',
                            options: {
                                useGeolocation: true,
                            } as GwFlowOtpOptions,
                        },
                        {
                            type: 'SELFIE_CAPTURE',
                            options: {
                                showTips: true,
                                showPreview: true,
                                checkLiveness: true,
                            } as GwFlowFaceCaptureOptions,
                        },
                        {
                            type: 'VALIDATE',
                            options: {} as GwFlowPersonalInfoOptions,
                        },
                    ],
                },
            },
        },

        {
            title: 'test with no scan',
            props: {
                onFinish: onFinish,
                onPartialResult,
                config: {
                    steps: [
                        { type: 'WELCOME' },
                        {
                            type: 'OTP_EMAIL',
                            options: {} as GwFlowOtpOptions,
                        },
                        {
                            type: 'DOCUMENT_TYPE_SELECT',
                            options: {
                                defaultDocumentCode: 'ID' as DocumentCode,
                                defaultCountryCode: 'URY',
                            } as GwFlowDocumentTypeOptions,
                        },
                        {
                            type: 'PERSONAL_INFO',
                            options: {
                                fullFields: true,
                                useGeolocation: false,
                            } as GwFlowPersonalInfoOptions,
                        },
                        {
                            type: 'OTP_SMS',
                            options: {
                                useGeolocation: false,
                            } as GwFlowOtpOptions,
                        },
                    ],
                },
            },
        },
        {
            title: 'only send result',
            props: {
                onFinish: onFinish,
                onPartialResult,
                config: {
                    steps: [
                        // { type: 'WELCOME' },
                        {
                            type: 'RESULT',
                            options: {} as GwFlowResultOptions,
                        },
                    ],
                },
            },
        },
    ];
    return (
        <div>
            <Collapse destroyInactivePanel={true} collapsible={'header'}>
                {samples.map(({ title, props }, index) => {
                    return (
                        <Collapse.Panel
                            header={title}
                            key={`fragment-${index}`}
                        >
                            <ConfigProvider
                                defaultTransactionId={
                                    '9907e586-539d-4f4b-bbc6-579a24517000'
                                }
                                defaultClientId={25}
                                config={{}}
                            >
                                <GwFlow {...props} />
                            </ConfigProvider>
                        </Collapse.Panel>
                    );
                })}
            </Collapse>
        </div>
    );
}

// export default HexagonGrid;
