import React, { useMemo } from 'react';
import './DocumentTypeSelect.css';
import Select, { SelectProps } from '../../common/Select';
import documents from 'gw-api/dist/data/documentTypes.json';
import { useTranslation } from 'react-i18next';
import { DocumentCode, DocumentInfo } from 'gw-api/dist/types';
import { Divider } from 'antd';

const documentList = Object.values(documents) as DocumentInfo[];
export default DocumentTypeSelect;

export type DocumentTypeSelectProps = SelectProps & {
    availableDocuments?: DocumentCode[];
    visibleDocuments?: DocumentCode[];
    popularDocuments?: DocumentCode[];
};

function DocumentTypeSelect({
    availableDocuments,
    visibleDocuments,
    popularDocuments = ['ID', 'PASSPORT', 'DL'],
    size,
    ...rest
}: DocumentTypeSelectProps) {
    const { t } = useTranslation();
    const documentsToShow = useMemo(() => {
        return visibleDocuments &&
            Array.isArray(visibleDocuments) &&
            visibleDocuments.length
            ? visibleDocuments
                  .map((code) => documents[code])
                  .filter((item) => !!item)
            : documentList;
    }, [visibleDocuments]);

    const popularDocumentsToShow = useMemo(() => {
        return popularDocuments && Array.isArray(popularDocuments)
            ? popularDocuments
                  .map((code) => documents[code])
                  .filter((item) => {
                      return documentsToShow.includes(item);
                  })
            : documentList;
    }, [popularDocuments]);

    const notPopularDocumentsToShow = useMemo(() => {
        return documentsToShow.filter(
            (item) => !popularDocumentsToShow.includes(item)
        );
    }, [documentsToShow, popularDocumentsToShow]);

    return (
        <div className="DocumentTypeSelect">
            <Select
                style={{
                    minWidth: '300px',
                    width: '100%',
                    textAlign: 'left',
                }}
                showSearch={true}
                options={[
                    ...popularDocumentsToShow.map((item) => ({
                        key: item.code,
                        value: item.code,
                        label: t(item.name),
                        disabled: !(
                            availableDocuments === undefined ||
                            (Array.isArray(availableDocuments) &&
                                availableDocuments.includes(item.code))
                        ),
                    })),
                    ...(popularDocumentsToShow.length &&
                    notPopularDocumentsToShow.length
                        ? [
                              {
                                  label: (
                                      <Divider
                                          key="divider"
                                          style={{ margin: 8 }}
                                      />
                                  ),
                              },
                          ]
                        : []),
                    ...notPopularDocumentsToShow.map((item) => ({
                        key: item.code,
                        value: item.code,
                        label: t(item.name),
                        disabled: !(
                            availableDocuments === undefined ||
                            (Array.isArray(availableDocuments) &&
                                availableDocuments.includes(item.code))
                        ),
                    })),
                ]}
                placeholder={t('Elige el tipo de documento')}
                {...rest}
            />
        </div>
    );
}
