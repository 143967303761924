import React, { useContext, useEffect } from 'react';
//@ts-ignore
import QRCode from 'qrcode.react';
import TitleText from './common/TitleText';
import { useTranslation } from 'react-i18next';
import { DebugContext } from './common/Debug';
import StepContent from './common/StepContent';
import { useConfigContext } from './components/ConfigContext';
import { Space } from 'antd';

export type QRCodeProps = {
    showLink?: boolean;
};
export default function QrCode({ showLink }: QRCodeProps) {
    const { logDebug } = useContext(DebugContext);
    const { t } = useTranslation();
    const { transactionId, frontendBaseUrl, token } = useConfigContext();
    const url = token
        ? `${frontendBaseUrl}/share?token=${token}`
        : `${frontendBaseUrl}/share/?id=${transactionId}`;
    useEffect(() => {
        logDebug('QR code shown');
    }, [logDebug]);

    return (
        <StepContent
            header={
                <TitleText key="title" size="large">
                    {t('Continua el proceso en tu teléfono')}
                </TitleText>
            }
            body={
                <Space direction="vertical" align="center">
                    <QRCode value={url} />
                    <br />
                    {showLink ? (
                        <div style={{ width: '400px' }}>
                            <a href={url}>{url}</a>
                        </div>
                    ) : null}
                </Space>
            }
        />
    );
}
