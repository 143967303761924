import {
    API_ERRORS,
    ApiError,
    ApiError404,
    ApiError400,
    ApiError500,
    ApiError500Liveness,
    ApiError500AuthenticateFacial,
    ApiError500ExtractDocumentData,
    ApiError500CivilCheck,
    ApiError500CivilAuthenticateFacial,
    ApiError500DocumentValidation,
    ApiError404TransactionNotFound,
} from 'gw-api/dist/error';
import { HTTP_STATUS_NAMES } from 'gw-api/dist/httpStatusCodes';

function throwError(responseData, ignore) {
    if (!ignore) {
        if (responseData.message) {
            // old way
            return new ApiError(
                API_ERRORS.GENERAL_ERROR,
                API_ERRORS.GENERAL_ERROR,
                responseData
            );
        }
        let errorClass;
        switch (responseData.error_type) {
            case API_ERRORS.LIVENESS_ERROR:
                errorClass = ApiError500Liveness;
                break;
            case API_ERRORS.AUTHENTICATE_FACIAL_ERROR:
                errorClass = ApiError500AuthenticateFacial;
                break;
            case API_ERRORS.EXTRACT_DOCUMENT_DATA_ERROR:
                errorClass = ApiError500ExtractDocumentData;
                break;
            case API_ERRORS.CIVIL_CHECK_ERROR:
                errorClass = ApiError500CivilCheck;
                break;
            case API_ERRORS.CIVIL_FACIAL_AUTHENTICATION_ERROR:
                errorClass = ApiError500CivilAuthenticateFacial;
                break;
            case API_ERRORS.DOCUMENT_VALIDATION_ERROR:
                errorClass = ApiError500DocumentValidation;
                break;
            case API_ERRORS.TRANSACTION_NOT_FOUND:
                errorClass = ApiError404TransactionNotFound;
                break;
            case HTTP_STATUS_NAMES.NOT_FOUND:
                errorClass = ApiError404;
                break;
            case HTTP_STATUS_NAMES.BAD_REQUEST:
                errorClass = ApiError400;
                break;
            case HTTP_STATUS_NAMES.INTERNAL_SERVER_ERROR:
                errorClass = ApiError500;
                break;
            default:
                errorClass = ApiError;
        }
        throw errorClass.fromResponse(responseData);
    }
}

export default async function request(
    uri,
    data = null,
    method = 'POST',
    headers,
    ignoreErrors
) {
    let body;
    if (data) {
        body = JSON.stringify(data);
    }
    let res;
    try {
        res = await fetch(uri, {
            method,
            body,
            headers,
            credentials:
                process.env.NODE_ENV === 'production'
                    ? 'same-origin'
                    : 'include',
        });
    } catch (err) {
        throw new Error(err.message);
    }
    if (res.status === 204) {
        // server returned "no content" status, nothing to parse
        return {};
    }
    let resData;
    try {
        resData = await res.json();
    } catch (err) {
        resData = {};
    }
    try {
        switch (res.status) {
            // no content
            case 204:
                return {};
            // catch all
            default:
                if (res.status >= 200 && res.status < 300) {
                    // ok
                    return resData;
                } else if ([400, 401, 403, 404, 409].includes(res.status)) {
                    throwError(resData, ignoreErrors);
                } else if (res.status >= 500 && res.status < 600) {
                    // internal server error
                    throwError(resData, ignoreErrors);
                } else {
                    // unknown server error
                    throwError({ message: 'Unknown' }, ignoreErrors);
                }
        }
    } catch (err) {
        throw err;
    }
}
