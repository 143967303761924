import React from 'react';
import { useTranslation } from 'react-i18next';
import TitleText from '../../common/TitleText';
import StepContent from '../../common/StepContent';
import { Form } from 'antd';
import { GwFlowOtpType } from './GwFlowOtp';
import { RightOutlined } from '@ant-design/icons';
import Button from '../../common/Button';
import Input from '../../common/Input';
import PhoneNumberInput from '../../common/PhoneNumberInput';
import { CountryInfo } from 'gw-api';

export default GwFlowOtpForm;

export interface GwFlowOtpFormValues {
    recipient: string;
}

export type GwFlowOtpFormProps = {
    otpType?: GwFlowOtpType;
    useGeolocation?: boolean;
    defaultCountryCode?: string;
    defaultPhone?: string;
    defaultEmail?: string;
    readonly?: boolean;
    disabled?: boolean;
    onSuccess?: (result: any) => void;
    onError?: (error: any) => void;
    onPhoneNumberChange?: (phone: string) => void;
    onCountryChange?: (country: CountryInfo) => void;
};

function GwFlowOtpForm({
    otpType,
    useGeolocation,
    defaultCountryCode,
    defaultPhone,
    defaultEmail,
    readonly,
    disabled,
    onSuccess,
    onError,
    onPhoneNumberChange,
    onCountryChange,
}: GwFlowOtpFormProps) {
    const { t } = useTranslation();

    const isSms = otpType === 'sms';
    const handleFormFinish = (values: GwFlowOtpFormValues) => {
        onSuccess?.(values);
    };
    return (
        <div className="GwFlowOtpForm">
            <StepContent
                header={
                    <TitleText size="large">
                        {isSms
                            ? t('Necesitamos verificar su número telefónico')
                            : t('Necesitamos verificar su email')}
                    </TitleText>
                }
                body={
                    <Form<GwFlowOtpFormValues>
                        onFinish={handleFormFinish}
                        layout={'vertical'}
                        initialValues={{
                            recipient: defaultPhone || defaultEmail,
                        }}
                    >
                        {isSms ? (
                            <Form.Item
                                name="recipient"
                                label={t('Numero de teléfono')}
                                required={!readonly && !disabled}
                                rules={[
                                    {
                                        required: true,
                                        message: t(
                                            'El número de teléfono es requerido'
                                        ),
                                    },
                                    {
                                        pattern: /^\+\d+$/,
                                        message: t(
                                            'El número de teléfono es invalido'
                                        ),
                                    },
                                ]}
                            >
                                <PhoneNumberInput
                                    defaultCountryCode={defaultCountryCode}
                                    useGeolocation={useGeolocation}
                                    onPhoneNumberChange={onPhoneNumberChange}
                                    onCountryChange={onCountryChange}
                                    disabled={disabled && !!defaultPhone}
                                    readOnly={readonly && !!defaultPhone}
                                    showFullCountryName={true}
                                    direction="horizontal"
                                />
                            </Form.Item>
                        ) : (
                            <Form.Item
                                name="recipient"
                                label="Email"
                                required={!readonly && !disabled}
                                rules={[
                                    {
                                        required: true,
                                        message: t('El email es requerido'),
                                    },
                                    {
                                        type: 'email',
                                        message: t('El email es invalido'),
                                    },
                                ]}
                            >
                                <Input
                                    placeholder={t(`Ingresa tu email`)}
                                    disabled={disabled && !!defaultEmail}
                                    readOnly={readonly && !!defaultEmail}
                                    style={
                                        readonly && !!defaultEmail
                                            ? {
                                                  border: 'none',
                                                  boxShadow: 'none',
                                                  backgroundColor:
                                                      'transparent',
                                                  cursor: 'text',
                                                  color: 'currentColor',
                                                  fontSize: '16px',
                                                  fontWeight: '600',
                                                  paddingLeft: '0',
                                              }
                                            : {}
                                    }
                                />
                            </Form.Item>
                        )}
                        <Button htmlType="submit" type="primary">
                            {t('Enviar')}
                        </Button>
                    </Form>
                }
            />
        </div>
    );
}
