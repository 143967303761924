// @ts-ignore
import Color from 'color';
import { ClientData, ClientParams } from 'gw-api/dist/types';
import { ApiError } from 'gw-api/dist/error';

export function getClientParams(sortVars: any) {
    let result: ClientParams = sortVars as ClientParams;
    result.error = result.error
        ? ApiError.fromResponse(result.error as any)
        : null;

    return result || {};
}

export function createStylesheet(uiConfig: any) {
    const rules = [];

    if (uiConfig.primaryColor) {
        rules.push(`
            .ant-btn.ant-btn-primary:not(.ant-btn[disabled]), 
            .ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-tail::after,
            .ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-icon { 
            background-color: ${uiConfig.primaryColor}; border-color: ${Color(
                uiConfig.primaryColor
            ).lighten(0.9)};}
            a, 
            .ant-radio-button-wrapper.ant-radio-button-wrapper-checked ,
            .ant-radio-button-wrapper:not(.ant-radio-button-wrapper-disabled):hover,
            .ant-btn.ant-btn-link:not(.ant-btn[disabled]), 
            .ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon,
             .ant-steps-item-custom.ant-steps-item-process .ant-steps-item-icon>.ant-steps-icon, 
             .ant-upload.ant-upload-drag p.ant-upload-drag-icon .anticon, .Summary_item-icon {
            color: ${uiConfig.primaryColor};
            }
            .ant-select:not(.ant-select-disabled):hover .ant-select-selector,
            .ant-select-focused.ant-select-single:not(.ant-select-customize-input) .ant-select-selector{
                border-color: ${uiConfig.primaryColor};
                box-shadow: 0 0 0 2px ${Color(uiConfig.primaryColor)
                    .alpha(0.3)
                    .lighten(0.1)};
            }
            .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
                background-color: ${Color(uiConfig.primaryColor)
                    .alpha(0.3)
                    .lighten(0.1)};
            }
            .ValidatoinWizardDocument_radio-item:hover:not(.ant-radio-button-wrapper-disabled), .Image, .ant-steps-item-finish .ant-steps-item-icon,
             .ant-upload.ant-upload-drag:not(.ant-upload-disabled):hover, 
            .DocumentTypeBoxSelect_radio-item.DocumentTypeBoxSelect_radio-item--selected,
             .ant-checkbox-checked .ant-checkbox-inner {
                border-color: ${uiConfig.primaryColor} !important;
            }
            .img-document-front-scanner, .img-document-back-scanner, .img-selfie-scanner{
                stroke: ${uiConfig.primaryColor} !important;
            }
            .img-result-success{
                fill: ${uiConfig.primaryColor} !important;
            }
            .ant-spin-dot-item,.ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title::after, 
            .ant-checkbox-checked .ant-checkbox-inner {
                background-color: ${uiConfig.primaryColor}
            }
        `);
    }
    if (uiConfig.borderRadiusBase) {
        rules.push(`
            .ant-btn:not(.ant-btn-circle), 
            .ant-input,
            .ant-select-single:not(.ant-select-customize-input) .ant-select-selector { 
                border-radius: ${uiConfig.borderRadiusBase}; 
            }
        `);
        rules.push(`
        .ant-input-group.ant-input-group-compact > *:last-child, .ant-input-group.ant-input-group-compact > .ant-select:last-child > .ant-select-selector, .ant-input-group.ant-input-group-compact > .ant-cascader-picker:last-child .ant-input, .ant-input-group.ant-input-group-compact > .ant-cascader-picker-focused:last-child .ant-input {
    border-top-right-radius: ${uiConfig.borderRadiusBase};
    border-bottom-right-radius: ${uiConfig.borderRadiusBase};
}`);
        rules.push(`.ant-input-group.ant-input-group-compact > .ant-select:first-child > .ant-select-selector {
            border-top-left-radius: 2px;
    border-bottom-left-radius: 2px;
        }`);
    }
    let style = document.createElement('style');
    style.type = 'text/css';
    style.innerHTML = rules.join('');
    window.document.getElementsByTagName('head')[0].appendChild(style);
}

export function updateFavicon(url: string) {
    document.querySelector('[rel=icon]')?.setAttribute('href', url);
}

export function getFormElement(data: any, action: string, method: string) {
    const form = window.document.createElement('form');
    form.setAttribute('action', action);
    form.setAttribute('method', method);

    for (let name in data) {
        const input = window.document.createElement('input');
        input.setAttribute('type', 'hidden');
        input.setAttribute('name', name);
        input.setAttribute(
            'value',
            typeof data[name] === 'object' && data[name] !== null
                ? JSON.stringify(data[name])
                : data[name]
        );
        form.appendChild(input);
    }
    return form;
}

export function submitForm(data: any, action: string, method: string) {
    const form = getFormElement(data, action, method);
    window.document.body.appendChild(form);
    form.submit();
}

export function cleanUpDocumentData(data: any) {
    const {
        faceImage,
        fullDocumentFrontImage,
        fullDocumentBackImage,
        fullDocumentImage,
        signatureImage,
        ...rest
    } = data;
    // @ts-ignore
    return rest;
}
