import React, { useMemo } from 'react';
import './DocumentTypeBoxSelect.css';
import { Radio, RadioChangeEvent, RadioGroupProps, Image } from 'antd';
import documents from 'gw-api/dist/data/documentTypes.json';
import { useTranslation } from 'react-i18next';
import { DocumentCode, DocumentInfo } from 'gw-api/dist/types';
import Space from '../../common/Space';
import ID_CARD from './ID_CARD.png';
import DRIVING_LICENSE from './DRIVING_LICENSE.png';
import PASSPORT from './PASSPORT.png';
import { Checkbox } from 'antd/es';
import classNames from 'classnames';
import TypographyText from '../../common/TypographyText';

const documentList = Object.values(documents) as DocumentInfo[];
export default DocumentTypeBoxSelect;

export type DocumentTypeBoxSelectProps = Omit<RadioGroupProps, 'onChange'> & {
    availableDocuments?: DocumentCode[];
    onChange?: (code: DocumentCode) => void;
};

function DocumentTypeBoxSelect({
    availableDocuments,
    onChange,
    value,
    ...rest
}: DocumentTypeBoxSelectProps) {
    const { t } = useTranslation();
    const documentsToShow = useMemo(() => documentList, []);

    const handleRadioChange = (event: RadioChangeEvent) => {
        onChange?.(event.target.value);
    };
    const handleCheckboxClick = (code: DocumentCode) => {
        onChange?.(code);
    };

    return (
        <div className="DocumentTypeBoxSelect">
            <Radio.Group
                size="large"
                style={{ width: '100%' }}
                onChange={handleRadioChange}
                value={value}
                {...rest}
            >
                {documentsToShow.map((item: DocumentInfo) => {
                    const disabled = !(
                        availableDocuments === undefined ||
                        (Array.isArray(availableDocuments) &&
                            availableDocuments.includes(item.code))
                    );
                    return (
                        <Radio.Button
                            disabled={disabled}
                            key={item.code}
                            className={classNames(
                                'DocumentTypeBoxSelect_radio-item',
                                {
                                    'DocumentTypeBoxSelect_radio-item--selected':
                                        item.code === value,
                                    'DocumentTypeBoxSelect_radio-item--disabled':
                                        disabled,
                                }
                            )}
                            value={item.code}
                        >
                            <Space className="DocumentTypeBoxSelect_radio-item-inner">
                                <Image
                                    className="DocumentTypeBoxSelect_radio-item-icon"
                                    src={getItemIcon(item.code)}
                                />
                                <span>
                                    {t(item.name)}
                                    <br />
                                    <TypographyText muted={true}>
                                        <small>
                                            {getItemDescription(item.code, t)}
                                        </small>
                                    </TypographyText>
                                </span>
                                <Checkbox
                                    checked={item.code === value}
                                    className="DocumentTypeBoxSelect_radio-item-checkbox"
                                    onClick={() =>
                                        handleCheckboxClick(item.code)
                                    }
                                    disabled={disabled}
                                />
                            </Space>
                        </Radio.Button>
                    );
                })}
            </Radio.Group>
        </div>
    );
}

export function getItemIcon(code: DocumentCode) {
    switch (code) {
        case DocumentCode.ID:
            return ID_CARD;
        case DocumentCode.DL:
            return DRIVING_LICENSE;
        case DocumentCode.PASSPORT:
            return PASSPORT;
        default:
            return ID_CARD;
    }
}
export function getItemDescription(code: DocumentCode, t: any) {
    switch (code) {
        case DocumentCode.ID:
            return t('Documento de identidad');
        case DocumentCode.DL:
            return t('Licencia de conducir');
        case DocumentCode.PASSPORT:
            return t('Pasaporte');
        default:
            return '';
    }
}
