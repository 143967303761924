import React, { useMemo, useState } from 'react';
import { GwFlowStepProps, GwFlowStepResult } from '../GwFlow';
import {
    GwConfigStepType,
    GwFlowPersonalInfoField,
    GwFlowPersonalInfoFieldType,
    GwFlowPersonalInfoOptions,
} from 'gw-api/dist/types';
import TitleText from '../../common/TitleText';
import { Form, Modal } from 'antd';
import StepContent from '../../common/StepContent';
import { useTranslation } from 'react-i18next';

import {
    GwFlowDocumentCaptureProviderResult,
    UserInfo,
} from 'gw-api/dist/types';
import Button from '../../common/Button';
import GwFlowPersonalInfoBasicForm, {
    validateMinAge,
} from './GwFlowPersonalInfoBasicForm';
import GwFlowPersonalInfoAdvancedForm from './GwFlowPersonalInfoAdvancedForm';
import GwFlowPersonalInfoContactForm from './GwFlowPersonalInfoContactForm';
import moment, { Moment } from 'moment';
import { useConfigContext } from '../ConfigContext';
import countries from '../../data/countries.json';
import jsonLogic from 'json-logic-js';

export default GwFlowPersonalInfo;
export const fieldTypes = [
    GwFlowPersonalInfoFieldType.firstName,
    GwFlowPersonalInfoFieldType.lastName,
    GwFlowPersonalInfoFieldType.dateOfBirth,
    GwFlowPersonalInfoFieldType.nationality,
    GwFlowPersonalInfoFieldType.documentType,
    GwFlowPersonalInfoFieldType.documentCountry,
    GwFlowPersonalInfoFieldType.documentNumber,
    GwFlowPersonalInfoFieldType.documentExpirationDate,
    GwFlowPersonalInfoFieldType.countryOfResidence,
    GwFlowPersonalInfoFieldType.cityOfResidence,
    GwFlowPersonalInfoFieldType.occupation,
    GwFlowPersonalInfoFieldType.education,
    GwFlowPersonalInfoFieldType.maritalStatus,
    GwFlowPersonalInfoFieldType.spouseName,
    GwFlowPersonalInfoFieldType.spouseSurname,
    GwFlowPersonalInfoFieldType.spouseIDNumber,
    GwFlowPersonalInfoFieldType.address,
    GwFlowPersonalInfoFieldType.email,
    GwFlowPersonalInfoFieldType.phone,
    GwFlowPersonalInfoFieldType.taxIdentificationNumber,
    GwFlowPersonalInfoFieldType.cpfNumber,
];

export type GwFlowPersonalInfoFieldFormValues = {
    firstName?: string;
    lastName?: string;
    dateOfBirth?: Moment;
    nationality?: string;
    documentType?: string;
    documentCountry?: string;
    documentNumber?: string;
    documentExpirationDate?: Moment;
    countryOfResidence?: string;
    cityOfResidence?: string;
    occupation?: string;
    education?: string;
    maritalStatus?: string;
    spouseName?: string;
    spouseSurname?: string;
    spouseIDNumber?: string;
    address?: string;
    email?: string;
    phone?: string;
    taxIdentificationNumber?: string;
    cpfNumber?: string;
};

export interface GwFlowPersonalInfoProps
    extends Omit<GwFlowStepProps, 'options'> {
    options?: GwFlowPersonalInfoOptions;
}

export type GwFlowPersonalInfoFieldMap = {
    [key in GwFlowPersonalInfoFieldType]?: GwFlowPersonalInfoField & {
        rules?: { required?: boolean }[];
        disabled: boolean;
    };
};

function GwFlowPersonalInfo({
    onResult,
    onCancel,
    options = {},
    currentResult,
}: Partial<GwFlowPersonalInfoProps>) {
    const { t } = useTranslation();
    const [form] = Form.useForm<GwFlowPersonalInfoFieldFormValues>();
    const {
        enableEdit,
        fields = [],
        fullFields,
        useGeolocation,
        availableDocuments,
        visibleDocuments,
        popularDocuments,
        validations,
        defaultCountryCode,
        defaultDocumentType,
        defaultDocumentCountry,
        defaultCountryOfResidence,
        defaultCityOfResidence,
        autofillCountryOfResidence,
        autofillCityOfResidence,
        allowDocumentNumberPicker,
    } = options;

    const { locale, geoLocation } = useConfigContext();

    const handleFinish = async () => {
        const values = await form.validateFields();

        try {
            if (values.dateOfBirth) {
                await validateMinAge(values.dateOfBirth, validations, t);
            }

            onResult?.({
                personalInfo: {
                    ...values,
                    dateOfBirth:
                        values.dateOfBirth &&
                        values.dateOfBirth.format(locale?.lang?.dateFormat),
                    dateFormat: locale?.lang?.dateFormat,
                    dateOfBirthISO: values.dateOfBirth?.toISOString(),
                    documentExpirationDate:
                        values.documentExpirationDate &&
                        values.documentExpirationDate.format(
                            locale?.lang?.dateFormat
                        ),
                    documentExpirationDateISO:
                        values.documentExpirationDate?.toISOString(),
                    documentCountryCode: values.documentCountry,
                    documentCountry: values.documentCountry
                        ? // @ts-ignore
                          countries[values.documentCountry]?.name
                        : '',
                    nationalityCode: values.nationality,
                    nationality: values.nationality
                        ? // @ts-ignore
                          countries[values.nationality]?.name
                        : '',
                    countryOfResidenceCode: values.countryOfResidence,
                    countryOfResidence: values.countryOfResidence
                        ? // @ts-ignore
                          countries[values.countryOfResidence]?.name
                        : '',
                },
            });
        } catch (err) {
            Modal.error({
                content: err.message,
            });
        }
    };

    const handleValueChanges = (changedValues: any) => {
        setFormValues(form.getFieldsValue());
    };

    const documentInfo = (
        (currentResult?.[
            GwConfigStepType.DOCUMENT_CAPTURE
        ] as GwFlowDocumentCaptureProviderResult) || {}
    )?.documentInfo;

    const initialValues = useMemo(
        () => ({
            // ...documentInfo,
            firstName: documentInfo?.firstName?.trim(),
            lastName: documentInfo?.lastName?.trim(),
            dateOfBirth: documentInfo?.dateOfBirthISO
                ? moment(documentInfo?.dateOfBirthISO)
                : moment(),
            documentExpirationDate: documentInfo?.dateOfExpiryISO
                ? moment(documentInfo?.dateOfExpiryISO)
                : moment(),
            phone: currentResult?.[GwConfigStepType.OTP_SMS]?.result?.recipient,
            email: currentResult?.[GwConfigStepType.OTP_EMAIL]?.email,
            documentType: documentInfo?.documentCode || defaultDocumentType,
            documentCountry:
                documentInfo?.documentIssuerAlpha3 ||
                defaultCountryCode ||
                defaultDocumentCountry,
            documentNumber:
                documentInfo?.documentNumber ||
                documentInfo?.personalIdNumber ||
                documentInfo?.documentAdditionalNumber,
            nationality: documentInfo?.nationalityAlpha3,
            countryOfResidence:
                (autofillCountryOfResidence &&
                    documentInfo?.documentIssuerAlpha3) ||
                defaultCountryOfResidence,
            cityOfResidence:
                (autofillCityOfResidence && documentInfo?.placeOfBirth) ||
                defaultCityOfResidence,
        }),
        []
    );
    const [formValues, setFormValues] =
        useState<GwFlowPersonalInfoFieldMap>(initialValues);

    const defaultFields = [
        {
            type: GwFlowPersonalInfoFieldType.firstName,
            label: t('Nombres'),
            show: true,
            required: true,
            disabled: !enableEdit,
        },
        {
            type: GwFlowPersonalInfoFieldType.lastName,
            label: t('Apellidos'),
            show: true,
            required: true,
            disabled: !enableEdit,
        },
        {
            type: GwFlowPersonalInfoFieldType.dateOfBirth,
            label: t('Fecha de Nacimiento'),
            show: true,
            required: true,
            disabled: !enableEdit,
        },
        {
            type: GwFlowPersonalInfoFieldType.documentType,
            label: t('Tipo de documento'),
            show: true,
            required: true,
            disabled: !enableEdit,
        },
        {
            type: GwFlowPersonalInfoFieldType.documentCountry,
            label: t('País del documento'),
            show: true,
            required: true,
            disabled: !enableEdit,
        },
        {
            type: GwFlowPersonalInfoFieldType.documentNumber,
            label: t('Número de documento'),
            show: true,
            required: true,
            disabled: !enableEdit,
        },
        {
            type: GwFlowPersonalInfoFieldType.documentExpirationDate,
            label: t('Fecha de expiración del documento'),
            show: false,
            required: true,
            disabled: !enableEdit,
        },
        {
            type: GwFlowPersonalInfoFieldType.nationality,
            label: t('Nacionalidad'),
            show: true,
            required: false,
            disabled: !enableEdit,
        },
        {
            type: GwFlowPersonalInfoFieldType.countryOfResidence,
            label: t('País de residencia'),
            show: false,
            required: true,
        },
        {
            type: GwFlowPersonalInfoFieldType.cityOfResidence,
            label: t('Ciudad de residencia'),
            show: false,
            required: true,
        },
        {
            type: GwFlowPersonalInfoFieldType.occupation,
            label: t('Ocupación/Profesión'),
            show: false,
        },
        {
            type: GwFlowPersonalInfoFieldType.education,
            label: t('Nivel de educación'),
            show: false,
        },
        {
            type: GwFlowPersonalInfoFieldType.maritalStatus,
            label: t('Estado civil'),
            show: false,
        },
        {
            type: GwFlowPersonalInfoFieldType.spouseName,
            label: t('Nombre del conyuge'),
            show: false,
        },
        {
            type: GwFlowPersonalInfoFieldType.spouseSurname,
            label: t('Apellido del conyuge'),
            show: false,
        },
        {
            type: GwFlowPersonalInfoFieldType.spouseIDNumber,
            label: t('ID del conyuge'),
            show: false,
        },
        {
            type: GwFlowPersonalInfoFieldType.address,
            label: t('Dirección completa'),
            show: false,
        },
        {
            type: GwFlowPersonalInfoFieldType.email,
            label: t('Email'),
            show: false,
        },
        {
            type: GwFlowPersonalInfoFieldType.phone,
            label: t('Número de movil'),
            rules: [
                {
                    pattern: /^\+\d+$/,
                    message: t('Número de movil inválido'),
                },
            ],
            show: false,
        },
        {
            type: GwFlowPersonalInfoFieldType.taxIdentificationNumber,
            label: t('Número de identificación de impuesto'),
            show: false,
        },
        {
            type: GwFlowPersonalInfoFieldType.cpfNumber,
            label: t('Número de CPF'),
            show: {
                '===': [{ var: 'documentCountry' }, 'BRA'],
            },
            required: true,
            disabled: !enableEdit,
        },
    ];

    const evaluateShow = (show: boolean | object, data: any): boolean => {
        if (typeof show === 'boolean') {
            return show;
        } else {
            try {
                return jsonLogic.apply(show, data);
            } catch (e) {
                console.error('Error evaluating show condition', e, show);
                return false;
            }
        }
    };

    const fieldsMap = useMemo<GwFlowPersonalInfoFieldMap>(() => {
        return defaultFields.reduce<GwFlowPersonalInfoFieldMap>(
            (
                acc: GwFlowPersonalInfoFieldMap,
                curr: GwFlowPersonalInfoField
            ) => {
                const currType = curr.type as GwFlowPersonalInfoFieldType;
                const currRules = curr.rules || [];
                const configField = fields.find(
                    (field) => field.type === currType
                );
                const {
                    required: configFieldRequired,
                    disabled: configFieldDisabled,
                    show: configFieldShow,
                    ...restConfigField
                } = configField || {};

                const isRequired = curr.required || !!configField?.required;
                const isDisabled = curr.disabled || !!configField?.disabled;
                const isShow =
                    configFieldShow !== undefined ? configFieldShow : curr.show;

                const { required, ...restCurr } = {
                    ...acc[currType],
                    ...curr,
                    rules: isRequired
                        ? [
                              ...currRules,
                              {
                                  required: true,
                                  message: `${t('Por favor ingresa')} ${curr.label}`,
                              },
                          ]
                        : currRules,
                    show: evaluateShow(
                        isShow !== undefined ? isShow : true,
                        formValues
                    ),
                    disabled: isDisabled,
                    ...restConfigField,
                };

                acc[currType] = restCurr;
                return acc;
            },
            {}
        );
    }, [formValues, enableEdit]);
    return (
        <StepContent
            header={
                <TitleText size="large">
                    {t('Necesitaremos algunos datos personales')}
                </TitleText>
            }
            body={
                <Form
                    form={form}
                    layout="vertical"
                    onFinish={handleFinish}
                    initialValues={initialValues}
                    onValuesChange={handleValueChanges}
                >
                    <GwFlowPersonalInfoBasicForm
                        form={form}
                        fieldsMap={fieldsMap}
                        initialValues={initialValues}
                        availableDocuments={availableDocuments}
                        visibleDocuments={
                            documentInfo?.documentCode
                                ? [
                                      ...(visibleDocuments || []),
                                      documentInfo.documentCode,
                                  ]
                                : visibleDocuments
                        }
                        popularDocuments={popularDocuments}
                        documentNumberOptions={
                            allowDocumentNumberPicker
                                ? [
                                      ...(documentInfo?.documentNumber
                                          ? [documentInfo?.documentNumber]
                                          : []),
                                      ...(documentInfo?.documentAdditionalNumber
                                          ? [
                                                documentInfo.documentAdditionalNumber,
                                            ]
                                          : []),
                                      ...(documentInfo?.personalIdNumber
                                          ? [documentInfo.personalIdNumber]
                                          : []),
                                  ]
                                : []
                        }
                        validations={validations}
                    />
                    <GwFlowPersonalInfoAdvancedForm
                        form={form}
                        fieldsMap={fieldsMap}
                    />
                    <GwFlowPersonalInfoContactForm
                        form={form}
                        fieldsMap={fieldsMap}
                        defaultCountryCode={
                            defaultCountryCode || geoLocation?.country_code2
                        }
                        useGeolocation={useGeolocation}
                    />
                    <Button htmlType="submit" type="primary">
                        {t('Continuar')}
                    </Button>
                </Form>
            }
        />
    );
}
export function getFieldsMap(t: any) {
    const fieldInfos = {
        [GwFlowPersonalInfoFieldType.firstName]: { label: t('Nombres') },
        [GwFlowPersonalInfoFieldType.lastName]: { label: t('Apellidos') },
        [GwFlowPersonalInfoFieldType.dateOfBirth]: {
            label: t('Fecha de Nacimiento'),
        },
        [GwFlowPersonalInfoFieldType.documentType]: {
            label: t('Tipo de documento'),
        },
        [GwFlowPersonalInfoFieldType.documentCountry]: {
            label: t('País del documento'),
        },
        [GwFlowPersonalInfoFieldType.documentNumber]: {
            label: t('Número de documento'),
        },
        [GwFlowPersonalInfoFieldType.documentExpirationDate]: {
            label: t('Fecha de expiración del documento'),
        },
        [GwFlowPersonalInfoFieldType.nationality]: { label: t('Nacionalidad') },
        [GwFlowPersonalInfoFieldType.countryOfResidence]: {
            label: t('País de residencia'),
        },
        [GwFlowPersonalInfoFieldType.cityOfResidence]: {
            label: t('Ciudad de residencia'),
        },
        [GwFlowPersonalInfoFieldType.occupation]: {
            label: t('Ocupación/Profesión'),
        },
        [GwFlowPersonalInfoFieldType.education]: {
            label: t('Nivel de educación'),
        },
        [GwFlowPersonalInfoFieldType.maritalStatus]: {
            label: t('Estado civil'),
        },
        [GwFlowPersonalInfoFieldType.spouseName]: {
            label: t('Nombre del conyuge'),
        },
        [GwFlowPersonalInfoFieldType.spouseSurname]: {
            label: t('Apellido del conyuge'),
        },
        [GwFlowPersonalInfoFieldType.spouseIDNumber]: {
            label: t('ID del conyuge'),
        },
        [GwFlowPersonalInfoFieldType.address]: {
            label: t('Dirección completa'),
        },
        [GwFlowPersonalInfoFieldType.email]: { label: t('Email') },
        [GwFlowPersonalInfoFieldType.phone]: {
            label: t('Número de movil'),
        },
        [GwFlowPersonalInfoFieldType.taxIdentificationNumber]: {
            label: t('Número de identificación de impuesto'),
        },
        [GwFlowPersonalInfoFieldType.cpfNumber]: { label: t('Número de CPF') },
    };
    return fieldInfos;
}

function hasBasicInfo(fieldsMap: GwFlowPersonalInfoFieldMap) {
    return (
        fieldsMap[GwFlowPersonalInfoFieldType.firstName]?.show ||
        fieldsMap[GwFlowPersonalInfoFieldType.lastName]?.show ||
        fieldsMap[GwFlowPersonalInfoFieldType.dateOfBirth]?.show ||
        fieldsMap[GwFlowPersonalInfoFieldType.nationality]?.show ||
        fieldsMap[GwFlowPersonalInfoFieldType.documentType]?.show ||
        fieldsMap[GwFlowPersonalInfoFieldType.documentCountry]?.show ||
        fieldsMap[GwFlowPersonalInfoFieldType.documentNumber]?.show
    );
}

function hasAdvancedInfo(fieldsMap: GwFlowPersonalInfoFieldMap) {
    return (
        fieldsMap[GwFlowPersonalInfoFieldType.education]?.show ||
        fieldsMap[GwFlowPersonalInfoFieldType.occupation]?.show ||
        fieldsMap[GwFlowPersonalInfoFieldType.maritalStatus]?.show ||
        fieldsMap[GwFlowPersonalInfoFieldType.spouseIDNumber]?.show ||
        fieldsMap[GwFlowPersonalInfoFieldType.spouseSurname]?.show ||
        fieldsMap[GwFlowPersonalInfoFieldType.spouseName]?.show
    );
}
function hasContactInfo(fieldsMap: GwFlowPersonalInfoFieldMap) {
    return (
        fieldsMap[GwFlowPersonalInfoFieldType.email]?.show ||
        fieldsMap[GwFlowPersonalInfoFieldType.address]?.show ||
        fieldsMap[GwFlowPersonalInfoFieldType.phone]?.show
    );
}
