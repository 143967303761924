import React from 'react';
import Image from '../../common/Image';
import { Trans } from 'react-i18next';
import Confirm, { ConfirmProps } from '../../common/Confirm';
import { GwFlowFaceCaptureProviderResult } from './GwFlowFaceCapture';
import SquareImage from '../../common/SquareImage';

export default GwFlowFaceCaptureConfirm;

export type GwFlowFaceCaptureConfirmProps = ConfirmProps & {
    result: GwFlowFaceCaptureProviderResult;
};

function GwFlowFaceCaptureConfirm({
    result,
    ...rest
}: GwFlowFaceCaptureConfirmProps) {
    return (
        <Confirm
            content={[
                <SquareImage
                    key="1"
                    // style={{
                    //     width: '100%',
                    //     maxWidth: '50%',
                    //     height: 'auto',
                    //     maxHeight: '300px',
                    //     objectFit: 'cover', // This ensures the image covers the area without stretching, but it may crop the image.
                    // }}
                    src={result?.bestImage}
                    className={undefined}
                    alt={undefined}
                />,
                <Trans
                    key="2"
                    i18nKey="Asegúrate que <1>la iluminación de la fotografía sea buena</1> antes de continuar"
                >
                    Asegúrate que
                    <strong>
                        la iluminación de la fotografía sea buena
                    </strong>{' '}
                    antes de continuar
                </Trans>,
            ]}
            {...rest}
        />
    );
}
