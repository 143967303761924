import React from 'react';
import './Button.css';
import { Button as AntButton, ButtonProps } from 'antd';
import { LeftOutlined } from '@ant-design/icons';

export default ButtonBack;

export type ButtonBackProps = ButtonProps & {};
function ButtonBack({ style, children, ...rest }: ButtonBackProps) {
    return (
        <AntButton
            type="link"
            style={{
                fontSize: '1.5em',
                background: 'rgba(255, 255, 255, 0.7)',
                borderRadius: '50%',
                height: '40px',
                width: '40px',
                padding: 0,
                lineHeight: '30px',
                paddingRight: '2px',
                ...style,
            }}
            {...rest}
        >
            {children || <LeftOutlined />}
        </AntButton>
    );
}
