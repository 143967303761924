import React from 'react';
import Select, { SelectProps } from '../../common/Select';

import countries from '../../data/countries.json';
import { getSortAlphabeticFn } from '../../common/sorting';
import { countryCodeToFlagEmoji } from '../../common/PhoneNumberInput';
import { useTranslation } from 'react-i18next';
import { CountryInfo } from 'gw-api/dist/types';

const countryList: CountryInfo[] = Object.values(countries).filter(
    (c) => !['CU', 'VE'].includes(c.alpha2)
);

countryList.sort(getSortAlphabeticFn('ascend', (x) => x.name));

export default CountrySelect;

export type CountrySelectProps = SelectProps & {
    showFullName?: boolean;
};

function CountrySelect({ showFullName, ...rest }: CountrySelectProps) {
    const { t } = useTranslation();
    return (
        <Select
            showSearch={true}
            optionFilterProp="name"
            style={{
                minWidth: '300px',
                width: '100%',
                textAlign: 'left',
            }}
            options={countryList.map((c) => ({
                key: c.alpha3,
                value: c.alpha3,
                label: (
                    <span>
                        <span style={{ fontSize: '20px' }}>
                            {countryCodeToFlagEmoji(c.alpha2)}
                        </span>
                        {'  '}
                        {c.name} ({c.alpha2})
                    </span>
                ),
                name: c.name,
            }))}
            placeholder={t('Elige el país emisor')}
            {...rest}
        />
    );
}
