import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Spin } from 'antd';
import GwService from '../services/GwService';
import {
    ClientParamsGwConfig,
    DocumentCaptureProvider,
    FaceCaptureProvider,
} from 'gw-api/dist/types';
import locales from '../data/locales.json';
import TitleText from '../common/TitleText';
import Space from '../common/Space';
import StepContent from '../common/StepContent';

interface AuthContextInterface {
    transactionId?: string;
    clientId?: number;
    flowId?: number;
    config?: ClientParamsGwConfig;
    defaultDocumentCaptureProvider: DocumentCaptureProvider;
    defaultFaceCaptureProvider: FaceCaptureProvider;
    frontendBaseUrl: string;
    apiBaseUrl: string;
    locale: any;

    geoLocation?: any;

    token: string | null;
}
export const ConfigContext = React.createContext<AuthContextInterface>(
    {} as AuthContextInterface
);

ConfigContext.displayName = 'Auth';

export type ConfigProviderProps = {
    children: React.ReactNode;
    defaultTransactionId?: string;
    defaultClientId?: number;
    defaultFlowId?: number;
    defaultToken?: string;
    onLoad?: () => void;
    config?: any;
    defaultLocale?: string;
    geoLocation?: any;
};
export const ConfigProvider = ({
    defaultTransactionId,
    defaultFlowId,
    config,
    children,
    onLoad,
    defaultLocale,
    geoLocation,
}: ConfigProviderProps) => {
    const [transactionId] = useState<string | undefined>(defaultTransactionId);
    const [flowId] = useState<number | undefined>(defaultFlowId);
    const [loading] = useState<boolean>(false);

    useEffect(() => {
        transactionId && GwService.setTransactionId(transactionId);
    }, [transactionId]);

    useEffect(() => {
        // flowId && GwService.setFlowId(transactionId);
    }, [flowId]);

    useEffect(() => {
        if (transactionId) {
            (async () => {
                await onLoad?.();
            })();
        }
    }, [transactionId]);

    const defaultDocumentCaptureProvider =
        (process.env.REACT_APP_SMARTFACE_DOCUMENT_CAPTURE_DEFAULT_PROVIDER &&
            !Number.isNaN(
                process.env
                    .REACT_APP_SMARTFACE_DOCUMENT_CAPTURE_DEFAULT_PROVIDER
            ) &&
            Number.parseInt(
                process.env
                    .REACT_APP_SMARTFACE_DOCUMENT_CAPTURE_DEFAULT_PROVIDER
            )) ||
        DocumentCaptureProvider.MICROBLINK;
    const defaultFaceCaptureProvider =
        (process.env.REACT_APP_SMARTFACE_FACE_CAPTURE_DEFAULT_PROVIDER &&
            !Number.isNaN(
                process.env.REACT_APP_SMARTFACE_FACE_CAPTURE_DEFAULT_PROVIDER
            ) &&
            Number.parseInt(
                process.env.REACT_APP_SMARTFACE_FACE_CAPTURE_DEFAULT_PROVIDER
            )) ||
        FaceCaptureProvider.IDRND;

    const authContext = {
        config,
        transactionId,
        flowId,
        defaultDocumentCaptureProvider,
        defaultFaceCaptureProvider,
        frontendBaseUrl: process.env.REACT_APP_SMARTFACE_FRONTEND_URL,
        apiBaseUrl: process.env.REACT_APP_SMARTFACE_BASE_API,
        locale: defaultLocale || locales['es'],
        geoLocation,
    };
    const error = useMemo(() => {
        if (
            config?.formConfig?.steps &&
            Object.keys(config?.formConfig?.steps).length === 0
        ) {
            return 'No steps configured';
        } else {
            return null;
        }
    }, [config]);
    return (
        <ConfigContext.Provider value={authContext}>
            <Spin spinning={loading}>
                {!error ? (
                    children
                ) : (
                    <StepContent
                        header={<TitleText size="large">{'Error'}</TitleText>}
                        body={
                            <Space direction="vertical" align="center">
                                <Space
                                    style={{
                                        fontSize: '96px',
                                        color: config?.uiConfig?.primaryColor,
                                    }}
                                ></Space>
                                {error}
                            </Space>
                        }
                    />
                )}
            </Spin>
        </ConfigContext.Provider>
    );
};

export function useConfigContext() {
    return useContext(ConfigContext);
}
