import imageCompression from 'browser-image-compression';

export function getBase64(img: HTMLImageElement) {
    return img.getAttribute('src')?.split(',')[1];
}

export function imageDataToDataUrl(imageData: any) {
    const canvas = document.createElement('canvas');

    canvas.width = imageData.width;
    canvas.height = imageData.height;

    const context = canvas.getContext('2d');
    context?.putImageData(imageData, 0, 0);

    return canvas.toDataURL();
}

export function isVideoLinkValid(videoLink: string) {
    return videoLink && videoLink.substr(-4) === '.mp4';
}

export function sleep(ms: number) {
    return new Promise((resolve) => setTimeout(resolve, ms));
}

export async function getCompresedImageElement(rawImage: any) {
    const options = {
        maxSizeMB: 1,
        maxWidthOrHeight: 1920,
        useWebWorker: true,
    };
    const blob = await imageCompression.getFilefromDataUrl(
        imageDataToDataUrl(rawImage),
        'myfile'
    );
    const compressedFile = await imageCompression(blob, options);
    const compresssedBase64 =
        await imageCompression.getDataUrlFromFile(compressedFile);

    return createImageElement(compresssedBase64);
}
export async function getImageElement(rawImage: any) {
    const base64 = await imageCompression.getDataUrlFromFile(rawImage);

    return createImageElement(base64);
}

export function createImageElement(dataUrl: string) {
    const resultImage = new window.Image();
    resultImage.src = dataUrl;
    return resultImage;
}
