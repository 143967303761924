import React, { useContext, useState } from 'react';
import { GwFlowStepProps, GwFlowStepResult } from '../GwFlow';
import { Modal, Tabs } from 'antd';
import { useTranslation } from 'react-i18next';

import GwFlowOtpSend from './GwFlowOtpSend';
import GwFlowOtpForm, { GwFlowOtpFormValues } from './GwFlowOtpForm';
import useRetries from '../../common/useRetries';
import { DebugContext } from '../../common/Debug';
import { guessCountryFromPhoneNumber } from '../../common/PhoneNumberInput';
import countriesMap from '../../data/countries.json';
import {
    CountryInfo,
    GwConfigStepType,
    GwFlowOtpOptions,
} from 'gw-api/dist/types';
import { useConfigContext } from '../ConfigContext';
export default GwFlowOtp;

export type GwFlowOtpType = 'email' | 'sms';

export interface GwFlowOtpProps extends Omit<GwFlowStepProps, 'options'> {
    type?: GwFlowOtpType;
    options?: GwFlowOtpOptions;
}

function GwFlowOtp({
    type = 'sms',
    onResult,
    onCancel,
    onError,
    options,
    currentResult,
}: Partial<GwFlowOtpProps>) {
    const { t } = useTranslation();
    const { logDebug } = useContext(DebugContext);
    const {
        useGeolocation,
        resendTimeout,
        countryAlpha2,
        defaultCountryCode,
        retries,
        defaultEmail,
        defaultPhone,
        readonly,
        disabled,
    } = options || {};

    const { geoLocation } = useConfigContext();
    const { consumeRetry, getRetryError, retriesLeft } = useRetries(retries);

    const [userEmail, setUserEmail] = useState<string>(
        defaultEmail || currentResult?.PERSONAL_INFO?.personalInfo?.email
    );
    const [userPhone, setUserPhone] = useState<string>(
        defaultPhone || currentResult?.PERSONAL_INFO?.personalInfo?.phone
    );

    const [canceledByUser, setCanceledByUser] = useState<boolean>(false);
    const [countryCode, setCountryCode] = useState<string | undefined>(
        defaultCountryCode
            ? countriesMap[defaultCountryCode]?.alpha2
            : geoLocation.country_code2
              ? geoLocation.country_code2
              : countryAlpha2
    );
    const [rawPhone, setRawPhone] = useState<string>(() => {
        if (userPhone) {
            const [, num] = guessCountryFromPhoneNumber(userPhone);
            return num as string;
        } else {
            return '';
        }
    });
    const handleSmsFormSuccess = (values: GwFlowOtpFormValues) => {
        setUserPhone(values.recipient);
        setActiveKey('send');
    };
    const handleEmailFormSuccess = (values: GwFlowOtpFormValues) => {
        setUserEmail(values.recipient);
        setActiveKey('send');
    };
    const handleValidateSuccess = (result: any) => {
        onResult?.({
            ...result,
            email: userEmail,
            phone: rawPhone || undefined,
            country: countryCode,
        });
    };
    const handleValidateError = (error: any) => {
        logDebug(`Error validating otp ${type}`);
        logDebug(`Retries left: ${retriesLeft}`);
        Modal.confirm({
            title: t('El código ingresado es incorrecto'),
            content: error.message,
            onCancel: () => {
                onResult?.({
                    status: 'canceled',
                    statusMessage: error.message,
                });
            },
            cancelText: t('Cancelar'),
            onOk: () => {
                if (consumeRetry()) {
                    setActiveKey('form');
                    logDebug(`Retrying otp send ${type}`);
                    setCanceledByUser(true);
                } else {
                    logDebug(`No more retries for otp send ${type}`);
                    onResult?.({
                        status: 'warning',
                        statusMessage: getRetryError()!.message,
                    });
                }
            },
            okText: t('Reintentar'),
        });
    };
    const handleCancel = () => {
        if (activeKey === 'form') {
            onCancel?.();
        } else {
            setActiveKey('form');
            setCanceledByUser(true);
        }
    };
    const handlePhoneNumberChange = (phone: string) => {
        setRawPhone(phone);
    };

    const handleCountryChange = (country: CountryInfo) => {
        setCountryCode(country?.alpha2);
    };

    const items = [
        ...(type === 'email' &&
        (!currentResult?.PERSONAL_INFO?.personalInfo?.email || canceledByUser)
            ? [
                  {
                      label: '',
                      key: 'form',
                      children: (
                          <GwFlowOtpForm
                              useGeolocation={useGeolocation}
                              defaultCountryCode={countryCode}
                              defaultEmail={userEmail}
                              otpType={'email'}
                              onSuccess={handleEmailFormSuccess}
                              disabled={disabled}
                              readonly={readonly}
                          />
                      ),
                  },
              ]
            : []),
        ...(type === 'sms' &&
        (!currentResult?.PERSONAL_INFO?.personalInfo?.phone || canceledByUser)
            ? [
                  {
                      label: '',
                      key: 'form',
                      children: (
                          <GwFlowOtpForm
                              useGeolocation={useGeolocation}
                              defaultCountryCode={countryCode}
                              defaultPhone={userPhone}
                              otpType={'sms'}
                              onSuccess={handleSmsFormSuccess}
                              onPhoneNumberChange={handlePhoneNumberChange}
                              disabled={disabled}
                              readonly={readonly}
                              onCountryChange={handleCountryChange}
                          />
                      ),
                  },
              ]
            : []),
        {
            label: '',
            key: 'send',
            children: (
                <GwFlowOtpSend
                    phone={userPhone}
                    email={userEmail}
                    otpType={type}
                    resendTimeout={resendTimeout}
                    onSuccess={handleValidateSuccess}
                    onError={handleValidateError}
                    onCancel={handleCancel}
                    disabled={disabled && !!(defaultPhone || defaultEmail)}
                    readonly={readonly && !!(defaultPhone || defaultEmail)}
                />
            ),
        },
    ];
    const [activeKey, setActiveKey] = useState<string>(items[0].key);

    return (
        <Tabs
            items={items}
            renderTabBar={() => <span></span>}
            activeKey={activeKey}
            destroyInactiveTabPane={true}
        />
    );
}
