import React, { useEffect, useState, useRef } from 'react';
import { InputRef } from 'antd';
import countriesMap from '../data/countries.json';
import { SelectValue } from 'antd/lib/select';
import { CountryInfo } from 'gw-api/dist/types';
import TypographyText from './TypographyText';
import Input, { InputProps } from '../common/Input';
import Select from '../common/Select';
import classNames from 'classnames';
import './PhoneNumberInput.css';

const countries: CountryInfo[] = Object.values(countriesMap);

interface PhoneNumberInputProps extends Omit<InputProps, 'onChange'> {
    value?: string;
    showFullCountryName?: boolean;
    onChange?: (value: string) => void;
    useGeolocation?: boolean;
    defaultCountryCode?: string;
    onPhoneNumberChange?: (phone: string) => void;
    onCountryChange?: (country?: CountryInfo) => void;
    direction?: 'vertical' | 'horizontal';
}

const PhoneNumberInput: React.FC<PhoneNumberInputProps> = ({
    value,
    showFullCountryName,
    onChange,
    useGeolocation = false,
    defaultCountryCode,
    onPhoneNumberChange,
    onCountryChange,
    disabled,
    readOnly,
    direction = 'horizontal',
}) => {
    const [countryCode, setCountryCode] = useState<string | undefined>(
        defaultCountryCode || undefined
    );
    const [phoneNumber, setPhoneNumber] = useState<string | undefined>();
    const inputRef = useRef<InputRef>(null);

    useEffect(() => {
        if (value) {
            const [code, phone] = guessCountryFromPhoneNumber(value);
            setCountryCode(code);
            setPhoneNumber(phone);
        }
    }, [value]);

    useEffect(() => {
        if (useGeolocation) {
            // Get user's current location
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    // Determine user's country based on latitude and longitude
                    const country = countries.find((country) => {
                        const { latitude, longitude } = country as any;
                        if (!latitude || !longitude) return false;
                        const { coords } = position;
                        const distance = Math.sqrt(
                            (latitude - coords.latitude) ** 2 +
                                (longitude - coords.longitude) ** 2
                        );
                        console.log(coords.latitude, coords.longitude);
                        return distance < 1; // Check if within 1 degree of latitude and longitude
                    });
                    alert(country);
                    if (country) {
                        setCountryCode(country.alpha2); // Set default country code to user's country
                    }
                },
                (error) => {
                    console.log(error);
                }
            );
        }
    }, [useGeolocation]);

    const triggerOnChange = (cCode?: string, pNumber?: string) => {
        if (onChange) {
            const selectedCountry = countries.find(
                (country) => country.alpha2 === (cCode || countryCode)
            );
            const fullPhoneNumber =
                selectedCountry && selectedCountry.phone
                    ? `+${selectedCountry.phone}${pNumber ?? phoneNumber ?? ''}`
                    : pNumber ?? phoneNumber;
            onCountryChange?.(selectedCountry);
            onChange(fullPhoneNumber);
        }
    };
    const handleCountryCodeChange = (value: SelectValue) => {
        const selectedCountry = countries.find(
            (country) => country.alpha2 === (value || countryCode)
        );
        onCountryChange?.(selectedCountry);
        setCountryCode(value as string);
        if (phoneNumber !== undefined) {
            triggerOnChange(value as string, undefined);
        }
        inputRef.current && inputRef.current.focus();
    };

    const handlePhoneNumberChange = (
        e: React.ChangeEvent<HTMLInputElement>
    ) => {
        const { value } = e.target;
        // const phoneNumberWithoutLeadingZeroes = value.replace(/^0+/, '');
        setPhoneNumber(value);
        triggerOnChange(undefined, value);
        onPhoneNumberChange?.(value);
    };

    const countryOptions = countries.map((country: CountryInfo) => ({
        label: (
            <span>
                <span>
                    <span style={{ fontSize: '20px' }}>
                        {countryCodeToFlagEmoji(country.alpha2)}{' '}
                    </span>
                    <TypographyText muted={true}>
                        +{country.phone}
                    </TypographyText>
                    {showFullCountryName ? ` ${country.name}` : ''}
                </span>
            </span>
        ),
        value: country.alpha2,
        key: country.alpha2,
        country: country,
    }));

    const filterOption = (input: string, option: any) => {
        return (
            option.country.name.toLowerCase().indexOf(input.toLowerCase()) >= 0
        );
    };

    const currentCountry = countries.find((c) => c.alpha2 === countryCode);

    return (
        <div>
            {readOnly && currentCountry ? (
                <div
                    style={{
                        width: '100%',
                        textAlign: 'left',
                        border: 'none',
                        boxShadow: 'none',
                        backgroundColor: 'transparent',
                        cursor: 'text',
                        color: 'currentColor', // Adjust color as needed
                        fontSize: '16px',
                        fontWeight: '600',
                    }}
                >
                    {countryCodeToFlagEmoji(countryCode)}
                    {' +'}
                    {currentCountry.phone} {phoneNumber}
                </div>
            ) : (
                <div
                    // size={direction === 'vertical' ? 20 : 0}
                    className={classNames(
                        'PhoneNumberInput_wrapper',
                        `PhoneNumberInput_wrapper--${direction}`
                    )}
                >
                    <Select
                        showSearch
                        filterOption={filterOption}
                        value={countryCode}
                        options={countryOptions}
                        onChange={handleCountryCodeChange}
                        bordered={!readOnly}
                        showArrow={!readOnly}
                        disabled={disabled || readOnly}
                        dropdownMatchSelectWidth={false}
                    />
                    <Input
                        ref={inputRef}
                        value={phoneNumber}
                        onChange={handlePhoneNumberChange}
                        style={{
                            textAlign: 'left',
                        }}
                        disabled={disabled}
                    />
                </div>
            )}
        </div>
    );
};

export default PhoneNumberInput;

export function countryCodeToFlagEmoji(countryCode: string): string {
    return countryCode
        .toUpperCase()
        .replace(/./g, (char) =>
            String.fromCodePoint(char.charCodeAt(0) + 127397)
        );
}

export function guessCountryFromPhoneNumber(
    phoneNumberWithCode: string
): [string | undefined, string | undefined] {
    const countryCodeRegex = /^\+\d+/;
    const match = phoneNumberWithCode.match(countryCodeRegex);

    if (match) {
        const countryCodeWithPlus = match[0];
        const countryCodeWithoutPlus = countryCodeWithPlus.slice(1); // Remove the leading '+'

        // Find the country with the longest matching calling code
        const country = countries.reduce(
            (prevCountry, currentCountry) => {
                if (countryCodeWithoutPlus.startsWith(currentCountry.phone)) {
                    return prevCountry.phone.length >
                        currentCountry.phone.length
                        ? prevCountry
                        : currentCountry;
                }
                return prevCountry;
            },
            { phone: '' }
        );

        if (country.phone) {
            // Remove the country code from the phone number and trim leading zeroes
            const cleanedPhoneNumber = phoneNumberWithCode
                .replace(`+${country.phone}`, '')
                .replace(/^0+/, '');

            return [country.alpha2, cleanedPhoneNumber];
        }
    }

    return [undefined, undefined];
}
