import React, { Fragment, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import Icon from '@ant-design/icons';
import { imgResultSuccessSvg } from '../../common/images';
import Button from '../../common/Button';
import TitleText from '../../common/TitleText';
import Space from '../../common/Space';
import Summary from './Summary';
import { GwFlowGlobalResult } from 'gw-api/dist/types';

export default GwFlowValidateSuccess;

export type GwFlowValidateSuccessProps = {
    result?: GwFlowGlobalResult;
    showSummary?: boolean;
    onFinishClick?: () => void;
    onFinishTimeout?: () => void;
    finishTimeoutMs?: number;
    title?: string;
    subtitle?: string;
    buttonText?: string;
};

function GwFlowValidateSuccess({
    result = {},
    showSummary,
    onFinishClick,
    onFinishTimeout,
    finishTimeoutMs,
    title,
    subtitle,
    buttonText,
}: GwFlowValidateSuccessProps) {
    const { t } = useTranslation();
    const timer = useRef<any>();

    useEffect(() => {
        if (finishTimeoutMs) {
            timer.current = setTimeout(() => {
                onFinishTimeout?.();
            }, finishTimeoutMs);
        }
        return () => {
            clearTimeout(timer.current);
            timer.current = null;
        };
    }, []);

    return (
        <div className="GwFlowValidateSuccess">
            {showSummary ? (
                <TitleText size="large">
                    {title || t('¡Felicitaciones!')}
                </TitleText>
            ) : null}
            {showSummary ? (
                <Summary result={result} />
            ) : (
                <div>
                    <br />
                    <br />
                    <br />
                    <br />
                    <Icon component={imgResultSuccessSvg} />
                </div>
            )}
            <Space direction="vertical" size={20} wide={true}>
                {showSummary ? (
                    <span></span>
                ) : (
                    <TitleText size="large">
                        {title || t('¡Felicitaciones!')}
                    </TitleText>
                )}
                <TitleText size="small">
                    {subtitle || t('Gracias por verificar tu identidad')}
                </TitleText>
                <Button type="primary" size="large" onClick={onFinishClick}>
                    {buttonText || t('Finalizar')}
                </Button>
            </Space>
        </div>
    );
}
