import React, { useState } from 'react';
import { Collapse } from 'antd';
import { ConfigProvider } from '../components/ConfigContext';
import GwFlowApp, { GwFlowAppProps } from '../GwFlowApp';
import {
    GwConfigStepType,
    GwFlowResultOptions,
    FaceCaptureProvider,
    GwFlowFaceCaptureOptions,
    GwFlowValidateOptions,
    DocumentCaptureProvider,
} from 'gw-api/dist/types';
import GwService from '../services/GwService';
import currentResultForValidate from '../mock/currentResultForValidate';

export default DebugGwFlowAppSelect;
export type DebugGwFlowAppProps = {};

function DebugGwFlowAppSelect({ ...rest }: DebugGwFlowAppProps) {
    const [ready, setReady] = useState<boolean>(false);
    const samples: {
        title: string;
        props: GwFlowAppProps;
    }[] = [
        {
            title: 'validate and send result',
            props: {
                overrideflowConfig: [
                    {
                        type: GwConfigStepType.VALIDATE,
                        options: {
                            showSummary: true,
                        } as GwFlowValidateOptions,
                    },

                    {
                        type: GwConfigStepType.RESULT,
                        options: {} as GwFlowResultOptions,
                    },
                ],
            },
        },
        {
            title: 'personal info',
            props: {
                overrideflowConfig: [
                    {
                        type: GwConfigStepType.PERSONAL_INFO,
                        options: {
                            fullFields: true,
                        },
                    },
                ],
            },
        },
        {
            title: 'document capture',
            props: {
                overrideflowConfig: [
                    {
                        type: GwConfigStepType.DOCUMENT_CAPTURE,
                        options: {
                            provider: DocumentCaptureProvider.MICROBLINK,
                        },
                    },
                ],
            },
        },
    ];
    return (
        <div>
            <Collapse destroyInactivePanel={true}>
                {samples.map(({ title, props }, index) => {
                    return (
                        <Collapse.Panel
                            header={title}
                            key={`fragment-${index}`}
                        >
                            <ConfigProvider
                                onLoad={async () => {
                                    try {
                                        await GwService.update({
                                            index: 0,
                                            result: currentResultForValidate,
                                        });
                                        setReady(true);
                                    } catch (e) {
                                        console.error(e);
                                    }
                                }}
                                defaultTransactionId={
                                    '9907e586-539d-4f4b-bbc6-579a24517000'
                                }
                                defaultClientId={25}
                                config={{
                                    idCliente: '25',
                                    flowId: '1',
                                    forceBackendConfig: true,
                                    clientDataConfig: {
                                        wizardMode: 'full',
                                        saasMode: 1,
                                        countryCode: 'URY',
                                        maxRetries: 2,
                                        showSummary: true,
                                        enabledConfirmSelfie: false,
                                        selfieCaptureRetries: 1,
                                        documentValidation: false,
                                        padValidation: false,
                                        documentCaptureRetries: 1,
                                        civilCheck: false,
                                        disableOcr: false,
                                        authenticateTime: 2,
                                        cropFactor: 0.6,
                                        videoMode: '',
                                        qrDeviceTransfer: true,
                                        mobileOnly: false,
                                        interactible: false,
                                    },
                                    resultConfig: {
                                        redirectMethod: 'GET',
                                        redirectUrl:
                                            'http://localhost:3003/testRedirect',
                                        returnPostData: true,
                                        returnMethod: 'POST',
                                        resultUrl:
                                            'http://localhost:3003/testResult',
                                        resultHeaders: '',
                                        resultContentType: 'application/json',
                                        arnSnsNotification:
                                            'arn:aws:sns:sa-east-1:772436477025:smartface-onboarding-gw-v4-result-notification',
                                    },
                                    responseTypeConfig: {
                                        imageFront: true,
                                        imageBack: true,
                                        imageUser: true,
                                        video: true,
                                        templateRaw: true,
                                    },
                                    uiConfig: {
                                        logoUrl:
                                            'https://gw-client-logos.s3-sa-east-1.amazonaws.com/87.svg',
                                        primaryColor: '#663BD1',
                                        favicon:
                                            'https://gw-client-logos.s3-sa-east-1.amazonaws.com/87.ico',
                                    },
                                    formConfig: {
                                        availableDocuments: [
                                            'ID_CARD',
                                            'PASSPORT',
                                        ],
                                    },
                                }}
                            >
                                {ready ? (
                                    <GwFlowApp
                                        {...props}
                                        style={{ zIndex: 1000 }}
                                    />
                                ) : null}
                            </ConfigProvider>
                        </Collapse.Panel>
                    );
                })}
            </Collapse>
        </div>
    );
}

// export default HexagonGrid;
